import Vue from "vue";

Vue.mixin({
    methods: {
        getColorCode(color) {
            let code = "";
            switch (color) {
                case "red":
                    code = "#E15A5A";
                    break;
                case "skyblue":
                    code = "#3D68FF";
                    break;
                case "yellow":
                    code = "#FFD66B";
                    break;
                case "green":
                    code = "#55C984";
                    break;
                case "orange":
                    code = "#FF9B57";
                    break;
                case "purple":
                    code = "#6A5AE0";
                    break;
                case "white":
                    code = "#FFFFFF";
                    break;
                case "black":
                    code = "#000000";
                    break;

                default:
                    break;
            }

            return code;
        },
        lockCasual(data) {
            if (this.surveyType == "casual") {
                if (data.casualAllow) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    },
});
