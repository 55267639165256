import { stimuls, stimulsWord } from './constant';

const initState = () => ({
  token: "",
  user: null,
  breadcrumbs: [],
  mode: 1,
  stimuls: stimuls,
  stimulsWord: stimulsWord,
  steps: [],
  disconnect: null,
  surveyType: "",
});

export default initState;
