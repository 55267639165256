export default [
    {
        id: "1",
        en: "can",
        th: "กระป๋อง",
        code: "2"
    },
    {
        id: "2",
        en: "one",
        th: "หนึ่ง",
        code: "3"
    },
    {
        id: "3",
        en: "people",
        th: "ผู้คน",
        code: "1"
    },
    {
        id: "4",
        en: "time",
        th: "เวลา",
        code: "1"
    },
    {
        id: "5",
        en: "work",
        th: "งาน",
        code: "1"
    },
    {
        id: "6",
        en: "water",
        th: "น้ำ",
        code: "1"
    },
    {
        id: "7",
        en: "history",
        th: "ประวัติศาสตร์",
        code: "1"
    },
    {
        id: "8",
        en: "way",
        th: "เส้นทาง",
        code: "2"
    },
    {
        id: "9",
        en: "art",
        th: "ศิลปะ",
        code: "1"
    },
    {
        id: "10",
        en: "money",
        th: "เงิน",
        code: "1"
    },
    {
        id: "11",
        en: "world",
        th: "โลก",
        code: "1"
    },
    {
        id: "12",
        en: "information",
        th: "ข้อมูล",
        code: "1"
    },
    {
        id: "13",
        en: "map",
        th: "แผนที่",
        code: "1"
    },
    {
        id: "14",
        en: "two",
        th: "สอง",
        code: "3"
    },
    {
        id: "15",
        en: "family",
        th: "ครอบครัว",
        code: "2"
    },
    {
        id: "16",
        en: "example",
        th: "ตัวอย่าง",
        code: "1"
    },
    {
        id: "17",
        en: "business",
        th: "ธุรกิจ",
        code: "1"
    },
    {
        id: "18",
        en: "being",
        th: "สิ่งมีชีวิต",
        code: "3"
    },
    {
        id: "19",
        en: "health",
        th: "สุขภาพ",
        code: "1"
    },
    {
        id: "20",
        en: "game",
        th: "เกม",
        code: "1"
    },
    {
        id: "21",
        en: "system",
        th: "ระบบ",
        code: "1"
    },
    {
        id: "22",
        en: "life",
        th: "ชีวิต",
        code: "1"
    },
    {
        id: "23",
        en: "meat",
        th: "เนื้อ",
        code: "1"
    },
    {
        id: "24",
        en: "form",
        th: "รูปร่าง",
        code: "1"
    },
    {
        id: "25",
        en: "air",
        th: "อากาศ",
        code: "1"
    },
    {
        id: "26",
        en: "day",
        th: "กลางวัน",
        code: "2"
    },
    {
        id: "27",
        en: "place",
        th: "สถานที่",
        code: "1"
    },
    {
        id: "28",
        en: "number",
        th: "ตัวเลข",
        code: "1"
    },
    {
        id: "29",
        en: "public",
        th: "สาธารณะ",
        code: "1"
    },
    {
        id: "30",
        en: "year",
        th: "ปี",
        code: "1"
    },
    {
        id: "31",
        en: "part",
        th: "ชิ้นส่วน",
        code: "2"
    },
    {
        id: "32",
        en: "field",
        th: "สนาม",
        code: "1"
    },
    {
        id: "33",
        en: "fish",
        th: "ปลา",
        code: "1"
    },
    {
        id: "34",
        en: "human",
        th: "มนุษย์",
        code: "1"
    },
    {
        id: "35",
        en: "local",
        th: "ท้องถิ่น",
        code: "1"
    },
    {
        id: "36",
        en: "process",
        th: "กระบวนการ",
        code: "1"
    },
    {
        id: "37",
        en: "hand",
        th: "มือ",
        code: "1"
    },
    {
        id: "38",
        en: "music",
        th: "ดนตรี",
        code: "1"
    },
    {
        id: "39",
        en: "person",
        th: "บุคคล",
        code: "1"
    },
    {
        id: "40",
        en: "experience",
        th: "ประสบการณ์",
        code: "1"
    },
    {
        id: "41",
        en: "job",
        th: "อาชีพ",
        code: "2"
    },
    {
        id: "42",
        en: "book",
        th: "หนังสือ",
        code: "1"
    },
    {
        id: "43",
        en: "method",
        th: "วิธีการ",
        code: "2"
    },
    {
        id: "44",
        en: "point",
        th: "จุด",
        code: "1"
    },
    {
        id: "45",
        en: "food",
        th: "อาหาร",
        code: "1"
    },
    {
        id: "46",
        en: "home",
        th: "บ้าน",
        code: "1"
    },
    {
        id: "47",
        en: "theory",
        th: "ทฤษฎี",
        code: "1"
    },
    {
        id: "48",
        en: "economy",
        th: "เศรษฐกิจ",
        code: "1"
    },
    {
        id: "49",
        en: "tonight",
        th: "คืนนี้",
        code: "1"
    },
    {
        id: "50",
        en: "law",
        th: "กฎ",
        code: "1"
    },
    {
        id: "51",
        en: "value",
        th: "ค่านิยม",
        code: "2"
    },
    {
        id: "52",
        en: "body",
        th: "ร่างกาย",
        code: "2"
    },
    {
        id: "53",
        en: "market",
        th: "ตลาด",
        code: "1"
    },
    {
        id: "54",
        en: "set",
        th: "ชุด",
        code: "1"
    },
    {
        id: "55",
        en: "bird",
        th: "นก",
        code: "1"
    },
    {
        id: "56",
        en: "literature",
        th: "วรรณกรรม",
        code: "1"
    },
    {
        id: "57",
        en: "problem",
        th: "ปัญหา",
        code: "1"
    },
    {
        id: "58",
        en: "state",
        th: "สถานะ",
        code: "1"
    },
    {
        id: "59",
        en: "power",
        th: "พลัง",
        code: "1"
    },
    {
        id: "60",
        en: "radio",
        th: "วิทยุ",
        code: "1"
    },
    {
        id: "61",
        en: "economics",
        th: "เศรษฐศาสตร์",
        code: "1"
    },
    {
        id: "62",
        en: "company",
        th: "บริษัท",
        code: "1"
    },
    {
        id: "63",
        en: "price",
        th: "ราคา",
        code: "1"
    },
    {
        id: "64",
        en: "size",
        th: "ขนาด",
        code: "1"
    },
    {
        id: "65",
        en: "past",
        th: "อดีต",
        code: "1"
    },
    {
        id: "66",
        en: "television",
        th: "โทรทัศน์",
        code: "1"
    },
    {
        id: "67",
        en: "science",
        th: "วิทยาศาสตร์",
        code: "2"
    },
    {
        id: "68",
        en: "list",
        th: "รายการ",
        code: "1"
    },
    {
        id: "69",
        en: "mind",
        th: "จิตใจ",
        code: "1"
    },
    {
        id: "70",
        en: "library",
        th: "ห้องสมุด",
        code: "1"
    },
    {
        id: "71",
        en: "nature",
        th: "ธรรมชาติ",
        code: "1"
    },
    {
        id: "72",
        en: "fact",
        th: "ข้อเท็จจริง",
        code: "1"
    },
    {
        id: "73",
        en: "product",
        th: "ผลิตภัณฑ์",
        code: "1"
    },
    {
        id: "74",
        en: "line",
        th: "เส้น",
        code: "2"
    },
    {
        id: "75",
        en: "temperature",
        th: "อุณหภูมิ",
        code: "1"
    },
    {
        id: "76",
        en: "group",
        th: "กลุ่ม",
        code: "1"
    },
    {
        id: "77",
        en: "word",
        th: "คำ",
        code: "3"
    },
    {
        id: "78",
        en: "key",
        th: "กุญแจ",
        code: "1"
    },
    {
        id: "79",
        en: "light",
        th: "แสงสว่าง",
        code: "1"
    },
    {
        id: "80",
        en: "today",
        th: "วันนี้",
        code: "1"
    },
    {
        id: "81",
        en: "name",
        th: "ชื่อ",
        code: "1"
    },
    {
        id: "82",
        en: "school",
        th: "โรงเรียน",
        code: "1"
    },
    {
        id: "83",
        en: "major",
        th: "วิชาเอก",
        code: "3"
    },
    {
        id: "84",
        en: "amount",
        th: "จำนวน",
        code: "1"
    },
    {
        id: "85",
        en: "level",
        th: "ระดับ",
        code: "1"
    },
    {
        id: "86",
        en: "order",
        th: "คำสั่ง",
        code: "1"
    },
    {
        id: "87",
        en: "service",
        th: "บริการ",
        code: "1"
    },
    {
        id: "88",
        en: "area",
        th: "พื้นที่",
        code: "1"
    },
    {
        id: "89",
        en: "society",
        th: "สังคม",
        code: "1"
    },
    {
        id: "90",
        en: "activity",
        th: "กิจกรรม",
        code: "1"
    },
    {
        id: "91",
        en: "story",
        th: "เรื่องราว",
        code: "1"
    },
    {
        id: "92",
        en: "boss",
        th: "เจ้านาย",
        code: "1"
    },
    {
        id: "93",
        en: "industry",
        th: "อุตสาหกรรม",
        code: "1"
    },
    {
        id: "94",
        en: "media",
        th: "สื่อ",
        code: "1"
    },
    {
        id: "95",
        en: "thing",
        th: "สิ่ง",
        code: "3"
    },
    {
        id: "96",
        en: "sport",
        th: "กีฬา",
        code: "1"
    },
    {
        id: "97",
        en: "page",
        th: "หน้าหนังสือ",
        code: "3"
    },
    {
        id: "98",
        en: "term",
        th: "ภาคเรียน",
        code: "3"
    },
    {
        id: "99",
        en: "test",
        th: "การสอบ",
        code: "2"
    },
    {
        id: "100",
        en: "oven",
        th: "เตาอบ",
        code: "1"
    },
    {
        id: "101",
        en: "answer",
        th: "คำตอบ",
        code: "1"
    },
    {
        id: "102",
        en: "sound",
        th: "เสียง",
        code: "1"
    },
    {
        id: "103",
        en: "community",
        th: "ชุมชน",
        code: "1"
    },
    {
        id: "104",
        en: "definition",
        th: "คำนิยาม",
        code: "1"
    },
    {
        id: "105",
        en: "focus",
        th: "จุดสนใจ",
        code: "1"
    },
    {
        id: "106",
        en: "matter",
        th: "สสาร",
        code: "3"
    },
    {
        id: "107",
        en: "quality",
        th: "คุณภาพ",
        code: "1"
    },
    {
        id: "108",
        en: "soil",
        th: "ดิน",
        code: "1"
    },
    {
        id: "109",
        en: "language",
        th: "ภาษา",
        code: "1"
    },
    {
        id: "110",
        en: "board",
        th: "กระดาน",
        code: "1"
    },
    {
        id: "111",
        en: "oil",
        th: "น้ำมัน",
        code: "1"
    },
    {
        id: "112",
        en: "picture",
        th: "รูปภาพ",
        code: "1"
    },
    {
        id: "113",
        en: "potential",
        th: "ศักยภาพ",
        code: "1"
    },
    {
        id: "114",
        en: "player",
        th: "นักกีฬา",
        code: "2"
    },
    {
        id: "115",
        en: "week",
        th: "สัปดาห์",
        code: "1"
    },
    {
        id: "116",
        en: "garden",
        th: "สวน",
        code: "1"
    },
    {
        id: "117",
        en: "range",
        th: "พิสัย",
        code: "3"
    },
    {
        id: "118",
        en: "reason",
        th: "เหตุผล",
        code: "1"
    },
    {
        id: "119",
        en: "future",
        th: "อนาคต",
        code: "1"
    },
    {
        id: "120",
        en: "image",
        th: "ภาพ",
        code: "1"
    },
    {
        id: "121",
        en: "case",
        th: "กรณี",
        code: "1"
    },
    {
        id: "122",
        en: "cause",
        th: "สาเหตุ",
        code: "1"
    },
    {
        id: "123",
        en: "coast",
        th: "ชายฝั่ง",
        code: "1"
    },
    {
        id: "124",
        en: "country",
        th: "ประเทศ",
        code: "1"
    },
    {
        id: "125",
        en: "movie",
        th: "ภาพยนตร์",
        code: "1"
    },
    {
        id: "126",
        en: "organization",
        th: "องค์กร",
        code: "1"
    },
    {
        id: "127",
        en: "action",
        th: "หนังบู๊",
        code: "3"
    },
    {
        id: "128",
        en: "age",
        th: "อายุ",
        code: "1"
    },
    {
        id: "129",
        en: "boat",
        th: "เรือ",
        code: "1"
    },
    {
        id: "130",
        en: "result",
        th: "ผลลัพธ์",
        code: "1"
    },
    {
        id: "131",
        en: "section",
        th: "ส่วนงาน",
        code: "2"
    },
    {
        id: "132",
        en: "building",
        th: "อาคาร",
        code: "1"
    },
    {
        id: "133",
        en: "mouse",
        th: "หนู",
        code: "1"
    },
    {
        id: "134",
        en: "equipment",
        th: "อุปกรณ์",
        code: "1"
    },
    {
        id: "135",
        en: "cash",
        th: "เงินสด",
        code: "1"
    },
    {
        id: "136",
        en: "class",
        th: "ชั้นเรียน",
        code: "2"
    },
    {
        id: "137",
        en: "period",
        th: "ระยะเวลา",
        code: "1"
    },
    {
        id: "138",
        en: "tax",
        th: "ภาษี",
        code: "1"
    },
    {
        id: "139",
        en: "policy",
        th: "นโยบาย",
        code: "1"
    },
    {
        id: "140",
        en: "side",
        th: "ด้านข้าง",
        code: "1"
    },
    {
        id: "141",
        en: "subject",
        th: "วิชา",
        code: "2"
    },
    {
        id: "142",
        en: "basis",
        th: "พื้นฐาน",
        code: "1"
    },
    {
        id: "143",
        en: "boyfriend",
        th: "แฟน",
        code: "1"
    },
    {
        id: "144",
        en: "direction",
        th: "ทิศทาง",
        code: "1"
    },
    {
        id: "145",
        en: "strategy",
        th: "กลยุทธ์",
        code: "1"
    },
    {
        id: "146",
        en: "space",
        th: "ช่องว่าง",
        code: "1"
    },
    {
        id: "147",
        en: "deal",
        th: "ข้อเสนอ",
        code: "1"
    },
    {
        id: "148",
        en: "worth",
        th: "คุณค่า",
        code: "1"
    },
    {
        id: "149",
        en: "army",
        th: "กองทัพ",
        code: "1"
    },
    {
        id: "150",
        en: "camera",
        th: "กล้อง",
        code: "1"
    },
    {
        id: "151",
        en: "freedom",
        th: "เสรีภาพ",
        code: "1"
    },
    {
        id: "152",
        en: "paper",
        th: "กระดาษ",
        code: "1"
    },
    {
        id: "153",
        en: "stock",
        th: "หุ้น",
        code: "1"
    },
    {
        id: "154",
        en: "weather",
        th: "สภาพอากาศ",
        code: "1"
    },
    {
        id: "155",
        en: "environment",
        th: "สิ่งแวดล้อม",
        code: "1"
    },
    {
        id: "156",
        en: "chance",
        th: "โอกาส",
        code: "1"
    },
    {
        id: "157",
        en: "figure",
        th: "รูป",
        code: "1"
    },
    {
        id: "158",
        en: "man",
        th: "ผู้ชาย",
        code: "1"
    },
    {
        id: "159",
        en: "model",
        th: "แบบอย่าง",
        code: "1"
    },
    {
        id: "160",
        en: "source",
        th: "แหล่งที่มา",
        code: "1"
    },
    {
        id: "161",
        en: "child",
        th: "เด็ก",
        code: "1"
    },
    {
        id: "162",
        en: "month",
        th: "เดือน",
        code: "1"
    },
    {
        id: "163",
        en: "beginning",
        th: "จุดเริ่มต้น",
        code: "1"
    },
    {
        id: "164",
        en: "present",
        th: "ปัจจุบัน",
        code: "1"
    },
    {
        id: "165",
        en: "university",
        th: "มหาวิทยาลัย",
        code: "1"
    },
    {
        id: "166",
        en: "chicken",
        th: "ไก่",
        code: "1"
    },
    {
        id: "167",
        en: "feature",
        th: "คุณสมบัติ",
        code: "1"
    },
    {
        id: "168",
        en: "head",
        th: "ศีรษะ",
        code: "1"
    },
    {
        id: "169",
        en: "material",
        th: "วัสดุ",
        code: "1"
    },
    {
        id: "170",
        en: "purpose",
        th: "วัตถุประสงค์",
        code: "1"
    },
    {
        id: "171",
        en: "question",
        th: "คำถาม",
        code: "1"
    },
    {
        id: "172",
        en: "rock",
        th: "หิน",
        code: "1"
    },
    {
        id: "173",
        en: "salt",
        th: "เกลือ",
        code: "1"
    },
    {
        id: "174",
        en: "article",
        th: "บทความ",
        code: "1"
    },
    {
        id: "175",
        en: "department",
        th: "แผนก",
        code: "1"
    },
    {
        id: "176",
        en: "goal",
        th: "เป้าหมาย",
        code: "1"
    },
    {
        id: "177",
        en: "news",
        th: "ข่าว",
        code: "1"
    },
    {
        id: "178",
        en: "car",
        th: "รถยนต์",
        code: "1"
    },
    {
        id: "179",
        en: "dog",
        th: "สุนัข",
        code: "2"
    },
    {
        id: "180",
        en: "object",
        th: "วัตถุ",
        code: "1"
    },
    {
        id: "181",
        en: "scale",
        th: "มาตราส่วน",
        code: "1"
    },
    {
        id: "182",
        en: "sun",
        th: "ดวงอาทิตย์",
        code: "1"
    },
    {
        id: "183",
        en: "cost",
        th: "ค่าใช้จ่าย",
        code: "1"
    },
    {
        id: "184",
        en: "audience",
        th: "ผู้ชม",
        code: "1"
    },
    {
        id: "185",
        en: "income",
        th: "รายได้",
        code: "1"
    },
    {
        id: "186",
        en: "user",
        th: "ผู้ใช้",
        code: "1"
    },
    {
        id: "187",
        en: "profit",
        th: "กำไร",
        code: "1"
    },
    {
        id: "188",
        en: "war",
        th: "สงคราม",
        code: "1"
    },
    {
        id: "189",
        en: "meaning",
        th: "ความหมาย",
        code: "1"
    },
    {
        id: "190",
        en: "medicine",
        th: "ยา",
        code: "1"
    },
    {
        id: "191",
        en: "philosophy",
        th: "ปรัชญา",
        code: "1"
    },
    {
        id: "192",
        en: "bank",
        th: "ธนาคาร",
        code: "1"
    },
    {
        id: "193",
        en: "craft",
        th: "งานฝีมือ",
        code: "1"
    },
    {
        id: "194",
        en: "half",
        th: "ครึ่ง",
        code: "1"
    },
    {
        id: "195",
        en: "standard",
        th: "มาตรฐาน",
        code: "1"
    },
    {
        id: "196",
        en: "regular",
        th: "ปกติ",
        code: "1"
    },
    {
        id: "197",
        en: "teacher",
        th: "ครู",
        code: "1"
    },
    {
        id: "198",
        en: "bus",
        th: "รถประจำทาง",
        code: "2"
    },
    {
        id: "199",
        en: "eye",
        th: "ดวงตา",
        code: "1"
    },
    {
        id: "200",
        en: "fire",
        th: "ไฟ",
        code: "1"
    },
    {
        id: "201",
        en: "position",
        th: "ตำแหน่ง",
        code: "1"
    },
    {
        id: "202",
        en: "stress",
        th: "ความเครียด",
        code: "1"
    },
    {
        id: "203",
        en: "original",
        th: "ต้นฉบับ",
        code: "3"
    },
    {
        id: "204",
        en: "watch",
        th: "นาฬิกาข้อมือ",
        code: "2"
    },
    {
        id: "205",
        en: "night",
        th: "กลางคืน",
        code: "1"
    },
    {
        id: "206",
        en: "advantage",
        th: "ข้อได้เปรียบ",
        code: "1"
    },
    {
        id: "207",
        en: "benefit",
        th: "ผลประโยชน์",
        code: "1"
    },
    {
        id: "208",
        en: "box",
        th: "กล่อง",
        code: "1"
    },
    {
        id: "209",
        en: "frame",
        th: "กรอบ",
        code: "1"
    },
    {
        id: "210",
        en: "step",
        th: "ขั้นตอน",
        code: "1"
    },
    {
        id: "211",
        en: "chemistry",
        th: "เคมี",
        code: "1"
    },
    {
        id: "212",
        en: "disease",
        th: "โรค",
        code: "1"
    },
    {
        id: "213",
        en: "energy",
        th: "พลังงาน",
        code: "1"
    },
    {
        id: "214",
        en: "nation",
        th: "ชาติ",
        code: "3"
    },
    {
        id: "215",
        en: "road",
        th: "ถนน",
        code: "1"
    },
    {
        id: "216",
        en: "role",
        th: "บทบาท",
        code: "1"
    },
    {
        id: "217",
        en: "cycle",
        th: "วงจร",
        code: "1"
    },
    {
        id: "218",
        en: "face",
        th: "ใบหน้า",
        code: "1"
    },
    {
        id: "219",
        en: "item",
        th: "สิ่งของ",
        code: "2"
    },
    {
        id: "220",
        en: "metal",
        th: "โลหะ",
        code: "1"
    },
    {
        id: "221",
        en: "room",
        th: "ห้อง",
        code: "1"
    },
    {
        id: "222",
        en: "screen",
        th: "หน้าจอ",
        code: "1"
    },
    {
        id: "223",
        en: "structure",
        th: "โครงสร้าง",
        code: "1"
    },
    {
        id: "224",
        en: "location",
        th: "ที่ตั้ง",
        code: "1"
    },
    {
        id: "225",
        en: "account",
        th: "บัญชีผู้ใช้",
        code: "2"
    },
    {
        id: "226",
        en: "ball",
        th: "ลูกบอล",
        code: "1"
    },
    {
        id: "227",
        en: "addition",
        th: "การบวก",
        code: "3"
    },
    {
        id: "228",
        en: "education",
        th: "การศึกษา",
        code: "1"
    },
    {
        id: "229",
        en: "math",
        th: "คณิตศาสตร์",
        code: "1"
    },
    {
        id: "230",
        en: "moment",
        th: "ช่วงเวลา",
        code: "1"
    },
    {
        id: "231",
        en: "painting",
        th: "จิตรกรรม",
        code: "1"
    },
    {
        id: "232",
        en: "politics",
        th: "การเมือง",
        code: "1"
    },
    {
        id: "233",
        en: "balance",
        th: "สมดุล",
        code: "1"
    },
    {
        id: "234",
        en: "black",
        th: "สีดำ",
        code: "1"
    },
    {
        id: "235",
        en: "choice",
        th: "ทางเลือก",
        code: "1"
    },
    {
        id: "236",
        en: "gift",
        th: "ของขวัญ",
        code: "1"
    },
    {
        id: "237",
        en: "impact",
        th: "ผลกระทบ",
        code: "1"
    },
    {
        id: "238",
        en: "machine",
        th: "เครื่องจักร",
        code: "1"
    },
    {
        id: "239",
        en: "tool",
        th: "เครื่องมือ",
        code: "1"
    },
    {
        id: "240",
        en: "wind",
        th: "ลม",
        code: "1"
    },
    {
        id: "241",
        en: "white",
        th: "สีขาว",
        code: "1"
    },
    {
        id: "242",
        en: "event",
        th: "เหตุการณ์",
        code: "1"
    },
    {
        id: "243",
        en: "student",
        th: "นักเรียน",
        code: "1"
    },
    {
        id: "244",
        en: "wood",
        th: "ไม้",
        code: "1"
    },
    {
        id: "245",
        en: "address",
        th: "ที่อยู่",
        code: "1"
    },
    {
        id: "246",
        en: "culture",
        th: "วัฒนธรรม",
        code: "1"
    },
    {
        id: "247",
        en: "morning",
        th: "เช้า",
        code: "1"
    },
    {
        id: "248",
        en: "pot",
        th: "หม้อ",
        code: "1"
    },
    {
        id: "249",
        en: "sign",
        th: "สัญลักษณ์",
        code: "2"
    },
    {
        id: "250",
        en: "table",
        th: "โต๊ะ",
        code: "1"
    },
    {
        id: "251",
        en: "office",
        th: "สำนักงาน",
        code: "1"
    },
    {
        id: "252",
        en: "population",
        th: "ประชากร",
        code: "1"
    },
    {
        id: "253",
        en: "president",
        th: "ประธาน",
        code: "1"
    },
    {
        id: "254",
        en: "unit",
        th: "หน่วย",
        code: "1"
    },
    {
        id: "255",
        en: "condition",
        th: "สภาพ",
        code: "1"
    },
    {
        id: "256",
        en: "egg",
        th: "ไข่",
        code: "1"
    },
    {
        id: "257",
        en: "ice",
        th: "น้ำแข็ง",
        code: "1"
    },
    {
        id: "258",
        en: "network",
        th: "เครือข่าย",
        code: "1"
    },
    {
        id: "259",
        en: "north",
        th: "ทิศเหนือ",
        code: "1"
    },
    {
        id: "260",
        en: "square",
        th: "สี่เหลี่ยม",
        code: "1"
    },
    {
        id: "261",
        en: "category",
        th: "หมวดหมู่",
        code: "1"
    },
    {
        id: "262",
        en: "context",
        th: "บริบท",
        code: "1"
    },
    {
        id: "263",
        en: "performance",
        th: "ผลงาน",
        code: "1"
    },
    {
        id: "264",
        en: "date",
        th: "วันที่",
        code: "3"
    },
    {
        id: "265",
        en: "star",
        th: "ดาว",
        code: "1"
    },
    {
        id: "266",
        en: "driver",
        th: "คนขับรถ",
        code: "2"
    },
    {
        id: "267",
        en: "flight",
        th: "เที่ยวบิน",
        code: "1"
    },
    {
        id: "268",
        en: "magazine",
        th: "นิตยสาร",
        code: "1"
    },
    {
        id: "269",
        en: "newspaper",
        th: "หนังสือพิมพ์",
        code: "1"
    },
    {
        id: "270",
        en: "capital",
        th: "เงินทุน",
        code: "1"
    },
    {
        id: "271",
        en: "friend",
        th: "เพื่อน",
        code: "1"
    },
    {
        id: "272",
        en: "self",
        th: "ตัวเอง",
        code: "3"
    },
    {
        id: "273",
        en: "dealer",
        th: "ตัวแทนจำหน่าย",
        code: "3"
    },
    {
        id: "274",
        en: "lake",
        th: "ทะเลสาบ",
        code: "1"
    },
    {
        id: "275",
        en: "member",
        th: "สมาชิก",
        code: "1"
    },
    {
        id: "276",
        en: "message",
        th: "ข้อความ",
        code: "1"
    },
    {
        id: "277",
        en: "phone",
        th: "โทรศัพท์",
        code: "1"
    },
    {
        id: "278",
        en: "scene",
        th: "ฉาก",
        code: "1"
    },
    {
        id: "279",
        en: "brush",
        th: "แปรง",
        code: "1"
    },
    {
        id: "280",
        en: "couple",
        th: "คู่",
        code: "1"
    },
    {
        id: "281",
        en: "exit",
        th: "ทางออก",
        code: "1"
    },
    {
        id: "282",
        en: "front",
        th: "ด้านหน้า",
        code: "1"
    },
    {
        id: "283",
        en: "summer",
        th: "ฤดูร้อน",
        code: "1"
    },
    {
        id: "284",
        en: "taste",
        th: "รสชาติ",
        code: "1"
    },
    {
        id: "285",
        en: "wing",
        th: "ปีก",
        code: "1"
    },
    {
        id: "286",
        en: "lead",
        th: "ตะกั่ว",
        code: "1"
    },
    {
        id: "287",
        en: "association",
        th: "สมาคม",
        code: "1"
    },
    {
        id: "288",
        en: "concept",
        th: "แนวคิด",
        code: "1"
    },
    {
        id: "289",
        en: "customer",
        th: "ลูกค้า",
        code: "1"
    },
    {
        id: "290",
        en: "housing",
        th: "ที่อยู่อาศัย",
        code: "1"
    },
    {
        id: "291",
        en: "inflation",
        th: "เงินเฟ้อ",
        code: "1"
    },
    {
        id: "292",
        en: "insurance",
        th: "ประกันภัย",
        code: "1"
    },
    {
        id: "293",
        en: "mood",
        th: "อารมณ์",
        code: "1"
    },
    {
        id: "294",
        en: "woman",
        th: "ผู้หญิง",
        code: "1"
    },
    {
        id: "295",
        en: "brain",
        th: "สมอง",
        code: "1"
    },
    {
        id: "296",
        en: "button",
        th: "ปุ่ม",
        code: "1"
    },
    {
        id: "297",
        en: "foot",
        th: "เท้า",
        code: "1"
    },
    {
        id: "298",
        en: "influence",
        th: "อิทธิพล",
        code: "1"
    },
    {
        id: "299",
        en: "rain",
        th: "ฝน",
        code: "1"
    },
    {
        id: "300",
        en: "wall",
        th: "กำแพง",
        code: "1"
    },
    {
        id: "301",
        en: "handle",
        th: "ด้ามจับ",
        code: "1"
    },
    {
        id: "302",
        en: "advice",
        th: "คำแนะนำ",
        code: "1"
    },
    {
        id: "303",
        en: "situation",
        th: "สถานการณ์",
        code: "1"
    },
    {
        id: "304",
        en: "skill",
        th: "ทักษะ",
        code: "1"
    },
    {
        id: "305",
        en: "statement",
        th: "คำแถลง",
        code: "3"
    },
    {
        id: "306",
        en: "wealth",
        th: "ความมั่งคั่ง",
        code: "1"
    },
    {
        id: "307",
        en: "base",
        th: "ฐาน",
        code: "1"
    },
    {
        id: "308",
        en: "damage",
        th: "ความเสียหาย",
        code: "1"
    },
    {
        id: "309",
        en: "distance",
        th: "ระยะทาง",
        code: "1"
    },
    {
        id: "310",
        en: "staff",
        th: "พนักงาน",
        code: "1"
    },
    {
        id: "311",
        en: "sugar",
        th: "น้ำตาล",
        code: "1"
    },
    {
        id: "312",
        en: "target",
        th: "เป้า",
        code: "1"
    },
    {
        id: "313",
        en: "secret",
        th: "ความลับ",
        code: "1"
    },
    {
        id: "314",
        en: "spring",
        th: "ฤดูใบไม้ผลิ",
        code: "3"
    },
    {
        id: "315",
        en: "city",
        th: "เมือง",
        code: "1"
    },
    {
        id: "316",
        en: "county",
        th: "เขต",
        code: "1"
    },
    {
        id: "317",
        en: "estate",
        th: "อสังหาริมทรัพย์",
        code: "1"
    },
    {
        id: "318",
        en: "foundation",
        th: "รากฐาน",
        code: "1"
    },
    {
        id: "319",
        en: "grandmother",
        th: "ยาย",
        code: "1"
    },
    {
        id: "320",
        en: "heart",
        th: "หัวใจ",
        code: "1"
    },
    {
        id: "321",
        en: "perspective",
        th: "ทัศนคติ",
        code: "1"
    },
    {
        id: "322",
        en: "photo",
        th: "รูปถ่าย",
        code: "1"
    },
    {
        id: "323",
        en: "recipe",
        th: "สูตรอาหาร",
        code: "1"
    },
    {
        id: "324",
        en: "topic",
        th: "หัวข้อ",
        code: "1"
    },
    {
        id: "325",
        en: "animal",
        th: "สัตว์",
        code: "1"
    },
    {
        id: "326",
        en: "author",
        th: "ผู้เขียน",
        code: "1"
    },
    {
        id: "327",
        en: "budget",
        th: "งบประมาณ",
        code: "1"
    },
    {
        id: "328",
        en: "ground",
        th: "พื้น",
        code: "1"
    },
    {
        id: "329",
        en: "lesson",
        th: "บทเรียน",
        code: "2"
    },
    {
        id: "330",
        en: "minute",
        th: "นาที",
        code: "1"
    },
    {
        id: "331",
        en: "officer",
        th: "เจ้าหน้าที่",
        code: "1"
    },
    {
        id: "332",
        en: "phase",
        th: "ระยะ",
        code: "3"
    },
    {
        id: "333",
        en: "sky",
        th: "ท้องฟ้า",
        code: "1"
    },
    {
        id: "334",
        en: "stage",
        th: "เวที",
        code: "1"
    },
    {
        id: "335",
        en: "brown",
        th: "สีน้ำตาล",
        code: "1"
    },
    {
        id: "336",
        en: "collection",
        th: "ของสะสม",
        code: "1"
    },
    {
        id: "337",
        en: "imagination",
        th: "จินตนาการ",
        code: "1"
    },
    {
        id: "338",
        en: "passion",
        th: "แรงผลักดัน",
        code: "1"
    },
    {
        id: "339",
        en: "resource",
        th: "ทรัพยากร",
        code: "1"
    },
    {
        id: "340",
        en: "bowl",
        th: "ชาม",
        code: "1"
    },
    {
        id: "341",
        en: "bridge",
        th: "สะพาน",
        code: "1"
    },
    {
        id: "342",
        en: "character",
        th: "ตัวละคร",
        code: "2"
    },
    {
        id: "343",
        en: "edge",
        th: "ขอบ",
        code: "1"
    },
    {
        id: "344",
        en: "evidence",
        th: "หลักฐาน",
        code: "1"
    },
    {
        id: "345",
        en: "fan",
        th: "พัดลม",
        code: "1"
    },
    {
        id: "346",
        en: "letter",
        th: "จดหมาย",
        code: "1"
    },
    {
        id: "347",
        en: "lock",
        th: "แม่กุญแจ",
        code: "2"
    },
    {
        id: "348",
        en: "novel",
        th: "นิยาย",
        code: "1"
    },
    {
        id: "349",
        en: "option",
        th: "ตัวเลือก",
        code: "1"
    },
    {
        id: "350",
        en: "pack",
        th: "หีบห่อ",
        code: "1"
    },
    {
        id: "351",
        en: "park",
        th: "สวนสาธารณะ",
        code: "2"
    },
    {
        id: "352",
        en: "skin",
        th: "ผิว",
        code: "1"
    },
    {
        id: "353",
        en: "weight",
        th: "น้ำหนัก",
        code: "1"
    },
    {
        id: "354",
        en: "ad",
        th: "โฆษณา",
        code: "1"
    },
    {
        id: "355",
        en: "agency",
        th: "หน่วยงาน",
        code: "1"
    },
    {
        id: "356",
        en: "college",
        th: "วิทยาลัย",
        code: "1"
    },
    {
        id: "357",
        en: "criticism",
        th: "วิจารณ์",
        code: "3"
    },
    {
        id: "358",
        en: "description",
        th: "คำอธิบาย",
        code: "1"
    },
    {
        id: "359",
        en: "memory",
        th: "หน่วยความจำ",
        code: "1"
    },
    {
        id: "360",
        en: "secretary",
        th: "เลขานุการ",
        code: "1"
    },
    {
        id: "361",
        en: "solution",
        th: "สารละลาย",
        code: "1"
    },
    {
        id: "362",
        en: "baby",
        th: "ที่รัก",
        code: "1"
    },
    {
        id: "363",
        en: "background",
        th: "พื้นหลัง",
        code: "1"
    },
    {
        id: "364",
        en: "dish",
        th: "จาน",
        code: "1"
    },
    {
        id: "365",
        en: "factor",
        th: "ปัจจัย",
        code: "1"
    },
    {
        id: "366",
        en: "fruit",
        th: "ผลไม้",
        code: "1"
    },
    {
        id: "367",
        en: "glass",
        th: "กระจก",
        code: "1"
    },
    {
        id: "368",
        en: "joint",
        th: "ข้อต่อ",
        code: "1"
    },
    {
        id: "369",
        en: "master",
        th: "ผู้เชี่ยวชาญ",
        code: "1"
    },
    {
        id: "370",
        en: "muscle",
        th: "กล้ามเนื้อ",
        code: "1"
    },
    {
        id: "371",
        en: "red",
        th: "สีแดง",
        code: "1"
    },
    {
        id: "372",
        en: "trip",
        th: "การเดินทาง",
        code: "1"
    },
    {
        id: "373",
        en: "vegetable",
        th: "ผัก",
        code: "1"
    },
    {
        id: "374",
        en: "aspect",
        th: "ด้าน",
        code: "3"
    },
    {
        id: "375",
        en: "director",
        th: "ผู้อำนวยการ",
        code: "1"
    },
    {
        id: "376",
        en: "personality",
        th: "บุคลิกภาพ",
        code: "1"
    },
    {
        id: "377",
        en: "psychology",
        th: "จิตวิทยา",
        code: "1"
    },
    {
        id: "378",
        en: "storage",
        th: "พื้นที่จัดเก็บ",
        code: "1"
    },
    {
        id: "379",
        en: "version",
        th: "รุ่น",
        code: "1"
    },
    {
        id: "380",
        en: "appeal",
        th: "อุทธรณ์",
        code: "3"
    },
    {
        id: "381",
        en: "chart",
        th: "แผนภูมิ",
        code: "1"
    },
    {
        id: "382",
        en: "kitchen",
        th: "ห้องครัว",
        code: "2"
    },
    {
        id: "383",
        en: "land",
        th: "ที่ดิน",
        code: "1"
    },
    {
        id: "384",
        en: "log",
        th: "บันทึก",
        code: "3"
    },
    {
        id: "385",
        en: "mother",
        th: "แม่",
        code: "1"
    },
    {
        id: "386",
        en: "party",
        th: "งานสังสรรค์",
        code: "1"
    },
    {
        id: "387",
        en: "principle",
        th: "หลักการ",
        code: "1"
    },
    {
        id: "388",
        en: "relative",
        th: "ญาติ",
        code: "1"
    },
    {
        id: "389",
        en: "season",
        th: "ฤดูกาล",
        code: "1"
    },
    {
        id: "390",
        en: "signal",
        th: "สัญญาณ",
        code: "1"
    },
    {
        id: "391",
        en: "spirit",
        th: "วิญญาณ",
        code: "1"
    },
    {
        id: "392",
        en: "tree",
        th: "ต้นไม้",
        code: "1"
    },
    {
        id: "393",
        en: "wave",
        th: "คลื่น",
        code: "1"
    },
    {
        id: "394",
        en: "deposit",
        th: "เงินฝาก",
        code: "1"
    },
    {
        id: "395",
        en: "argument",
        th: "ข้อโต้แย้ง",
        code: "1"
    },
    {
        id: "396",
        en: "contract",
        th: "หนังสือสัญญา",
        code: "2"
    },
    {
        id: "397",
        en: "highway",
        th: "ทางหลวง",
        code: "1"
    },
    {
        id: "398",
        en: "union",
        th: "สหภาพ",
        code: "1"
    },
    {
        id: "399",
        en: "belt",
        th: "เข็มขัด",
        code: "1"
    },
    {
        id: "400",
        en: "bench",
        th: "ม้านั่ง",
        code: "1"
    },
    {
        id: "401",
        en: "commission",
        th: "คณะกรรมการ",
        code: "1"
    },
    {
        id: "402",
        en: "copy",
        th: "สำเนา",
        code: "1"
    },
    {
        id: "403",
        en: "project",
        th: "โครงการ",
        code: "1"
    },
    {
        id: "404",
        en: "sea",
        th: "ทะเล",
        code: "1"
    },
    {
        id: "405",
        en: "south",
        th: "ใต้",
        code: "1"
    },
    {
        id: "406",
        en: "ticket",
        th: "ตั๋ว",
        code: "1"
    },
    {
        id: "407",
        en: "agreement",
        th: "ข้อตกลง",
        code: "1"
    },
    {
        id: "408",
        en: "currency",
        th: "สกุลเงิน",
        code: "1"
    },
    {
        id: "409",
        en: "enineering",
        th: "วิศวกรรม",
        code: "1"
    },
    {
        id: "410",
        en: "interaction",
        th: "ปฏิสัมพันธ์",
        code: "1"
    },
    {
        id: "411",
        en: "mixture",
        th: "ส่วนผสม",
        code: "1"
    },
    {
        id: "412",
        en: "region",
        th: "ภูมิภาค",
        code: "1"
    },
    {
        id: "413",
        en: "republic",
        th: "สาธารณรัฐ",
        code: "1"
    },
    {
        id: "414",
        en: "tradition",
        th: "ธรรมเนียม",
        code: "1"
    },
    {
        id: "415",
        en: "angle",
        th: "มุม",
        code: "1"
    },
    {
        id: "416",
        en: "blue",
        th: "สีฟ้า",
        code: "1"
    },
    {
        id: "417",
        en: "breakfast",
        th: "อาหารเช้า",
        code: "1"
    },
    {
        id: "418",
        en: "daughter",
        th: "ลูกสาว",
        code: "1"
    },
    {
        id: "419",
        en: "degree",
        th: "วุฒิการศึกษา",
        code: "2"
    },
    {
        id: "420",
        en: "doctor",
        th: "หมอ",
        code: "1"
    },
    {
        id: "421",
        en: "dream",
        th: "ฝัน",
        code: "1"
    },
    {
        id: "422",
        en: "duty",
        th: "หน้าที่",
        code: "1"
    },
    {
        id: "423",
        en: "essay",
        th: "เรียงความ",
        code: "1"
    },
    {
        id: "424",
        en: "father",
        th: "พ่อ",
        code: "1"
    },
    {
        id: "425",
        en: "fee",
        th: "ค่าธรรมเนียม",
        code: "1"
    },
    {
        id: "426",
        en: "finance",
        th: "การเงิน",
        code: "1"
    },
    {
        id: "427",
        en: "hour",
        th: "ชั่วโมง",
        code: "1"
    },
    {
        id: "428",
        en: "juice",
        th: "น้ำผลไม้",
        code: "1"
    },
    {
        id: "429",
        en: "limit",
        th: "ขีดจำกัด",
        code: "1"
    },
    {
        id: "430",
        en: "luck",
        th: "โชค",
        code: "1"
    },
    {
        id: "431",
        en: "milk",
        th: "นม",
        code: "1"
    },
    {
        id: "432",
        en: "mouth",
        th: "ปาก",
        code: "1"
    },
    {
        id: "433",
        en: "peace",
        th: "สันติภาพ",
        code: "1"
    },
    {
        id: "434",
        en: "pipe",
        th: "ท่อ",
        code: "1"
    },
    {
        id: "435",
        en: "seat",
        th: "ที่นั่ง",
        code: "1"
    },
    {
        id: "436",
        en: "stable",
        th: "มั่นคง",
        code: "1"
    },
    {
        id: "437",
        en: "storm",
        th: "พายุ",
        code: "1"
    },
    {
        id: "438",
        en: "substance",
        th: "สาร",
        code: "1"
    },
    {
        id: "439",
        en: "minor",
        th: "ผู้เยาว์",
        code: "1"
    },
    {
        id: "440",
        en: "actor",
        th: "นักแสดง",
        code: "3"
    },
    {
        id: "441",
        en: "classroom",
        th: "ห้องเรียน",
        code: "1"
    },
    {
        id: "442",
        en: "drama",
        th: "ละคร",
        code: "1"
    },
    {
        id: "443",
        en: "enine",
        th: "เครื่องยนต์",
        code: "1"
    },
    {
        id: "444",
        en: "football",
        th: "ฟุตบอล",
        code: "1"
    },
    {
        id: "445",
        en: "hotel",
        th: "โรงแรม",
        code: "1"
    },
    {
        id: "446",
        en: "owner",
        th: "เจ้าของ",
        code: "1"
    },
    {
        id: "447",
        en: "priority",
        th: "ลำดับความสำคัญ",
        code: "1"
    },
    {
        id: "448",
        en: "afternoon",
        th: "เวลาบ่าย",
        code: "2"
    },
    {
        id: "449",
        en: "bat",
        th: "ค้างคาว",
        code: "1"
    },
    {
        id: "450",
        en: "beach",
        th: "ชายหาด",
        code: "1"
    },
    {
        id: "451",
        en: "chain",
        th: "โซ่",
        code: "1"
    },
    {
        id: "452",
        en: "crew",
        th: "ลูกเรือ",
        code: "2"
    },
    {
        id: "453",
        en: "detail",
        th: "รายละเอียด",
        code: "1"
    },
    {
        id: "454",
        en: "gold",
        th: "ทอง",
        code: "1"
    },
    {
        id: "455",
        en: "interview",
        th: "สัมภาษณ์",
        code: "1"
    },
    {
        id: "456",
        en: "mark",
        th: "เครื่องหมาย",
        code: "1"
    },
    {
        id: "457",
        en: "mission",
        th: "ภารกิจ",
        code: "1"
    },
    {
        id: "458",
        en: "score",
        th: "คะแนน",
        code: "1"
    },
    {
        id: "459",
        en: "sex",
        th: "เพศ",
        code: "1"
    },
    {
        id: "460",
        en: "shop",
        th: "ร้านค้า",
        code: "1"
    },
    {
        id: "461",
        en: "window",
        th: "หน้าต่าง",
        code: "1"
    },
    {
        id: "462",
        en: "atmosphere",
        th: "บรรยากาศ",
        code: "1"
    },
    {
        id: "463",
        en: "bread",
        th: "ขนมปัง",
        code: "1"
    },
    {
        id: "464",
        en: "candidate",
        th: "ผู้สมัคร",
        code: "1"
    },
    {
        id: "465",
        en: "climate",
        th: "ภูมิอากาศ",
        code: "1"
    },
    {
        id: "466",
        en: "employer",
        th: "นายจ้าง",
        code: "1"
    },
    {
        id: "467",
        en: "guest",
        th: "แขก",
        code: "1"
    },
    {
        id: "468",
        en: "height",
        th: "ความสูง",
        code: "1"
    },
    {
        id: "469",
        en: "mall",
        th: "ห้างสรรพสินค้า",
        code: "1"
    },
    {
        id: "470",
        en: "manager",
        th: "ผู้จัดการ",
        code: "1"
    },
    {
        id: "471",
        en: "agent",
        th: "ตัวแทน",
        code: "1"
    },
    {
        id: "472",
        en: "band",
        th: "วงดนตรี",
        code: "1"
    },
    {
        id: "473",
        en: "bone",
        th: "กระดูก",
        code: "1"
    },
    {
        id: "474",
        en: "calendar",
        th: "ปฏิทิน",
        code: "1"
    },
    {
        id: "475",
        en: "cap",
        th: "หมวก",
        code: "1"
    },
    {
        id: "476",
        en: "coat",
        th: "เสื้อโค้ท",
        code: "1"
    },
    {
        id: "477",
        en: "court",
        th: "ศาล",
        code: "1"
    },
    {
        id: "478",
        en: "cup",
        th: "ถ้วย",
        code: "1"
    },
    {
        id: "479",
        en: "district",
        th: "อำเภอ",
        code: "1"
    },
    {
        id: "480",
        en: "door",
        th: "ประตู",
        code: "1"
    },
    {
        id: "481",
        en: "east",
        th: "ทิศตะวันออก",
        code: "1"
    },
    {
        id: "482",
        en: "finger",
        th: "นิ้ว",
        code: "1"
    },
    {
        id: "483",
        en: "garage",
        th: "โรงรถ",
        code: "1"
    },
    {
        id: "484",
        en: "hole",
        th: "รู",
        code: "1"
    },
    {
        id: "485",
        en: "hook",
        th: "ตะขอ",
        code: "1"
    },
    {
        id: "486",
        en: "layer",
        th: "ชั้น",
        code: "1"
    },
    {
        id: "487",
        en: "nose",
        th: "จมูก",
        code: "1"
    },
    {
        id: "488",
        en: "parking",
        th: "ที่จอดรถ",
        code: "1"
    },
    {
        id: "489",
        en: "partner",
        th: "พันธมิตร",
        code: "1"
    },
    {
        id: "490",
        en: "profile",
        th: "ข้อมูลส่วนตัว",
        code: "1"
    },
    {
        id: "491",
        en: "rice",
        th: "ข้าว",
        code: "1"
    },
    {
        id: "492",
        en: "routine",
        th: "กิจวัตรประจำวัน",
        code: "1"
    },
    {
        id: "493",
        en: "schedule",
        th: "กำหนดการ",
        code: "1"
    },
    {
        id: "494",
        en: "tip",
        th: "เคล็ดลับ",
        code: "1"
    },
    {
        id: "495",
        en: "winter",
        th: "ฤดูหนาว",
        code: "1"
    },
    {
        id: "496",
        en: "initial",
        th: "อักษรย่อ",
        code: "1"
    },
    {
        id: "497",
        en: "march",
        th: "มีนาคม",
        code: "1"
    },
    {
        id: "498",
        en: "smell",
        th: "กลิ่น",
        code: "1"
    },
    {
        id: "499",
        en: "spiritual",
        th: "จิตวิญญาณ",
        code: "1"
    },
    {
        id: "500",
        en: "charity",
        th: "การกุศล",
        code: "1"
    },
    {
        id: "501",
        en: "cousin",
        th: "ลูกพี่ลูกน้อง",
        code: "1"
    },
    {
        id: "502",
        en: "disaster",
        th: "ภัยพิบัติ",
        code: "1"
    },
    {
        id: "503",
        en: "editor",
        th: "บรรณาธิการ",
        code: "1"
    },
    {
        id: "504",
        en: "efficiency",
        th: "ประสิทธิภาพ",
        code: "1"
    },
    {
        id: "505",
        en: "extent",
        th: "ขอบเขต",
        code: "1"
    },
    {
        id: "506",
        en: "feedback",
        th: "ข้อเสนอแนะ",
        code: "1"
    },
    {
        id: "507",
        en: "homework",
        th: "การบ้าน",
        code: "1"
    },
    {
        id: "508",
        en: "leader",
        th: "ผู้นำ",
        code: "1"
    },
    {
        id: "509",
        en: "refrigerator",
        th: "ตู้เย็น",
        code: "1"
    },
    {
        id: "510",
        en: "resolution",
        th: "ปณิธาน",
        code: "1"
    },
    {
        id: "511",
        en: "singer",
        th: "นักร้อง",
        code: "1"
    },
    {
        id: "512",
        en: "airline",
        th: "สายการบิน",
        code: "1"
    },
    {
        id: "513",
        en: "bag",
        th: "กระเป๋า",
        code: "1"
    },
    {
        id: "514",
        en: "bed",
        th: "เตียง",
        code: "1"
    },
    {
        id: "515",
        en: "bill",
        th: "ใบเสร็จ",
        code: "2"
    },
    {
        id: "516",
        en: "code",
        th: "รหัส",
        code: "1"
    },
    {
        id: "517",
        en: "curve",
        th: "เส้นโค้ง",
        code: "1"
    },
    {
        id: "518",
        en: "dimension",
        th: "มิติ",
        code: "1"
    },
    {
        id: "519",
        en: "dress",
        th: "ชุดกระโปรง",
        code: "2"
    },
    {
        id: "520",
        en: "emergency",
        th: "ภาวะฉุกเฉิน",
        code: "1"
    },
    {
        id: "521",
        en: "evening",
        th: "ตอนเย็น",
        code: "1"
    },
    {
        id: "522",
        en: "holiday",
        th: "วันหยุด",
        code: "1"
    },
    {
        id: "523",
        en: "horse",
        th: "ม้า",
        code: "1"
    },
    {
        id: "524",
        en: "host",
        th: "เจ้าภาพ",
        code: "1"
    },
    {
        id: "525",
        en: "husband",
        th: "สามี",
        code: "1"
    },
    {
        id: "526",
        en: "loan",
        th: "เงินกู้",
        code: "1"
    },
    {
        id: "527",
        en: "mountain",
        th: "ภูเขา",
        code: "1"
    },
    {
        id: "528",
        en: "nail",
        th: "เล็บ",
        code: "1"
    },
    {
        id: "529",
        en: "noise",
        th: "เสียงรบกวน",
        code: "1"
    },
    {
        id: "530",
        en: "package",
        th: "บรรจุุภัณฑ์",
        code: "1"
    },
    {
        id: "531",
        en: "phrase",
        th: "วลี",
        code: "1"
    },
    {
        id: "532",
        en: "sand",
        th: "ทราย",
        code: "1"
    },
    {
        id: "533",
        en: "sentence",
        th: "ประโยค",
        code: "1"
    },
    {
        id: "534",
        en: "shoulder",
        th: "ไหล่",
        code: "1"
    },
    {
        id: "535",
        en: "smoke",
        th: "ควัน",
        code: "1"
    },
    {
        id: "536",
        en: "stomach",
        th: "หน้าท้อง",
        code: "2"
    },
    {
        id: "537",
        en: "string",
        th: "เส้นด้าย",
        code: "2"
    },
    {
        id: "538",
        en: "tourist",
        th: "นักท่องเที่ยว",
        code: "1"
    },
    {
        id: "539",
        en: "towel",
        th: "ผ้าขนหนู",
        code: "1"
    },
    {
        id: "540",
        en: "west",
        th: "ทิศตะวันตก",
        code: "2"
    },
    {
        id: "541",
        en: "wheel",
        th: "ล้อรถ",
        code: "2"
    },
    {
        id: "542",
        en: "adult",
        th: "ผู้ใหญ่",
        code: "1"
    },
    {
        id: "543",
        en: "basket",
        th: "ตะกร้า",
        code: "1"
    },
    {
        id: "544",
        en: "cabinet",
        th: "ตู้",
        code: "1"
    },
    {
        id: "545",
        en: "childhood",
        th: "วัยเด็ก",
        code: "1"
    },
    {
        id: "546",
        en: "church",
        th: "คริสตจักร",
        code: "1"
    },
    {
        id: "547",
        en: "clothes",
        th: "เสื้อผ้า",
        code: "1"
    },
    {
        id: "548",
        en: "coffee",
        th: "กาแฟ",
        code: "1"
    },
    {
        id: "549",
        en: "dinner",
        th: "อาหารเย็น",
        code: "1"
    },
    {
        id: "550",
        en: "hair",
        th: "เส้นผม",
        code: "2"
    },
    {
        id: "551",
        en: "judgment",
        th: "คำพิพากษา",
        code: "1"
    },
    {
        id: "552",
        en: "mud",
        th: "โคลน",
        code: "1"
    },
    {
        id: "553",
        en: "orange",
        th: "ส้ม",
        code: "1"
    },
    {
        id: "554",
        en: "poetry",
        th: "บทกวี",
        code: "1"
    },
    {
        id: "555",
        en: "police",
        th: "ตำรวจ",
        code: "1"
    },
    {
        id: "556",
        en: "queen",
        th: "ราชินี",
        code: "1"
    },
    {
        id: "557",
        en: "ratio",
        th: "อัตราส่วน",
        code: "1"
    },
    {
        id: "558",
        en: "relation",
        th: "ความสัมพันธ์",
        code: "1"
    },
    {
        id: "559",
        en: "restaurant",
        th: "ร้านอาหาร",
        code: "1"
    },
    {
        id: "560",
        en: "sector",
        th: "ภาค",
        code: "1"
    },
    {
        id: "561",
        en: "signature",
        th: "ลายเซ็น",
        code: "1"
    },
    {
        id: "562",
        en: "song",
        th: "เพลง",
        code: "1"
    },
    {
        id: "563",
        en: "tooth",
        th: "ฟัน",
        code: "1"
    },
    {
        id: "564",
        en: "vehicle",
        th: "ยานพาหนะ",
        code: "1"
    },
    {
        id: "565",
        en: "volume",
        th: "ปริมาณ",
        code: "1"
    },
    {
        id: "566",
        en: "wife",
        th: "ภรรยา",
        code: "1"
    },
    {
        id: "567",
        en: "arm",
        th: "แขน",
        code: "1"
    },
    {
        id: "568",
        en: "border",
        th: "ชายแดน",
        code: "1"
    },
    {
        id: "569",
        en: "branch",
        th: "สาขา",
        code: "1"
    },
    {
        id: "570",
        en: "breast",
        th: "หน้าอก",
        code: "1"
    },
    {
        id: "571",
        en: "brother",
        th: "พี่ชาย",
        code: "1"
    },
    {
        id: "572",
        en: "chip",
        th: "มันฝรั่งทอด",
        code: "2"
    },
    {
        id: "573",
        en: "document",
        th: "เอกสาร",
        code: "1"
    },
    {
        id: "574",
        en: "draft",
        th: "แบบร่าง",
        code: "2"
    },
    {
        id: "575",
        en: "dust",
        th: "ฝุ่น",
        code: "1"
    },
    {
        id: "576",
        en: "habit",
        th: "นิสัย",
        code: "1"
    },
    {
        id: "577",
        en: "iron",
        th: "เหล็ก",
        code: "1"
    },
    {
        id: "578",
        en: "judge",
        th: "ผู้พิพากษา",
        code: "1"
    },
    {
        id: "579",
        en: "knife",
        th: "มีด",
        code: "1"
    },
    {
        id: "580",
        en: "landscape",
        th: "ภูมิประเทศ",
        code: "1"
    },
    {
        id: "581",
        en: "parent",
        th: "พ่อแม่",
        code: "1"
    },
    {
        id: "582",
        en: "pattern",
        th: "ลวดลาย",
        code: "1"
    },
    {
        id: "583",
        en: "pin",
        th: "เข็มหมุด",
        code: "1"
    },
    {
        id: "584",
        en: "pool",
        th: "สระน้ำ",
        code: "1"
    },
    {
        id: "585",
        en: "salary",
        th: "เงินเดือน",
        code: "1"
    },
    {
        id: "586",
        en: "shelter",
        th: "ที่หลบภัย",
        code: "1"
    },
    {
        id: "587",
        en: "shoe",
        th: "รองเท้า",
        code: "1"
    },
    {
        id: "588",
        en: "silver",
        th: "แร่เงิน",
        code: "1"
    },
    {
        id: "589",
        en: "tank",
        th: "ถัง",
        code: "1"
    },
    {
        id: "590",
        en: "train",
        th: "รถไฟ",
        code: "1"
    },
    {
        id: "591",
        en: "yellow",
        th: "สีเหลือง",
        code: "2"
    },
    {
        id: "592",
        en: "accident",
        th: "อุบัติเหตุ",
        code: "1"
    },
    {
        id: "593",
        en: "airport",
        th: "สนามบิน",
        code: "1"
    },
    {
        id: "594",
        en: "assumption",
        th: "สมมติฐาน",
        code: "1"
    },
    {
        id: "595",
        en: "chapter",
        th: "บท",
        code: "1"
    },
    {
        id: "596",
        en: "database",
        th: "ฐานข้อมูล",
        code: "1"
    },
    {
        id: "597",
        en: "error",
        th: "ข้อผิดพลาด",
        code: "1"
    },
    {
        id: "598",
        en: "farmer",
        th: "ชาวนา",
        code: "1"
    },
    {
        id: "599",
        en: "girl",
        th: "เด็กผู้หญิง",
        code: "2"
    },
    {
        id: "600",
        en: "hall",
        th: "ห้องโถง",
        code: "1"
    },
    {
        id: "601",
        en: "historian",
        th: "นักประวัติศาสตร์",
        code: "1"
    },
    {
        id: "602",
        en: "hospital",
        th: "โรงพยาบาล",
        code: "1"
    },
    {
        id: "603",
        en: "manufacturer",
        th: "ผู้ผลิต",
        code: "1"
    },
    {
        id: "604",
        en: "meal",
        th: "มื้ออาหาร",
        code: "2"
    },
    {
        id: "605",
        en: "reception",
        th: "แผนกต้อนรับ",
        code: "1"
    },
    {
        id: "606",
        en: "river",
        th: "แม่น้ำ",
        code: "1"
    },
    {
        id: "607",
        en: "son",
        th: "ลูกชาย",
        code: "1"
    },
    {
        id: "608",
        en: "speech",
        th: "คำพูด",
        code: "1"
    },
    {
        id: "609",
        en: "tea",
        th: "ชา",
        code: "1"
    },
    {
        id: "610",
        en: "village",
        th: "หมู่บ้าน",
        code: "1"
    },
    {
        id: "611",
        en: "warning",
        th: "คำเตือน",
        code: "1"
    },
    {
        id: "612",
        en: "winner",
        th: "ผู้ชนะ",
        code: "1"
    },
    {
        id: "613",
        en: "worker",
        th: "คนงาน",
        code: "1"
    },
    {
        id: "614",
        en: "writer",
        th: "นักเขียน",
        code: "1"
    },
    {
        id: "615",
        en: "bell",
        th: "ระฆัง",
        code: "2"
    },
    {
        id: "616",
        en: "bike",
        th: "จักรยาน",
        code: "1"
    },
    {
        id: "617",
        en: "boy",
        th: "เด็กผู้ชาย",
        code: "1"
    },
    {
        id: "618",
        en: "brick",
        th: "อิฐ",
        code: "1"
    },
    {
        id: "619",
        en: "chair",
        th: "เก้าอี้",
        code: "1"
    },
    {
        id: "620",
        en: "closet",
        th: "ตู้เสื้อผ้า",
        code: "1"
    },
    {
        id: "621",
        en: "clue",
        th: "เบาะแส",
        code: "1"
    },
    {
        id: "622",
        en: "collar",
        th: "ปลอกคอ",
        code: "1"
    },
    {
        id: "623",
        en: "comment",
        th: "ความคิดเห็น",
        code: "1"
    },
    {
        id: "624",
        en: "fuel",
        th: "เชื้อเพลิง",
        code: "1"
    },
    {
        id: "625",
        en: "glove",
        th: "ถุงมือ",
        code: "1"
    },
    {
        id: "626",
        en: "lunch",
        th: "อาหารกลางวัน",
        code: "1"
    },
    {
        id: "627",
        en: "mortgage",
        th: "จำนอง",
        code: "3"
    },
    {
        id: "628",
        en: "nurse",
        th: "พยาบาล",
        code: "1"
    },
    {
        id: "629",
        en: "pace",
        th: "ก้าว",
        code: "1"
    },
    {
        id: "630",
        en: "peak",
        th: "จุดสูงสุด",
        code: "1"
    },
    {
        id: "631",
        en: "plane",
        th: "เครื่องบิน",
        code: "1"
    },
    {
        id: "632",
        en: "reward",
        th: "รางวัล",
        code: "1"
    },
    {
        id: "633",
        en: "row",
        th: "แถว",
        code: "1"
    },
    {
        id: "634",
        en: "weekend",
        th: "สุดสัปดาห์",
        code: "1"
    },
    {
        id: "635",
        en: "yard",
        th: "ลาน",
        code: "1"
    },
    {
        id: "636",
        en: "upstairs",
        th: "ชั้นบน",
        code: "1"
    },
    {
        id: "637",
        en: "breath",
        th: "ลมหายใจ",
        code: "1"
    },
    {
        id: "638",
        en: "buyer",
        th: "ผู้ซื้อ",
        code: "1"
    },
    {
        id: "639",
        en: "conclusion",
        th: "บทสรุป",
        code: "1"
    },
    {
        id: "640",
        en: "desk",
        th: "โต๊ะเรียน",
        code: "2"
    },
    {
        id: "641",
        en: "drawer",
        th: "ลิ้นชัก",
        code: "1"
    },
    {
        id: "642",
        en: "establishment",
        th: "สถานประกอบการ",
        code: "1"
    },
    {
        id: "643",
        en: "grocery",
        th: "ร้านขายของชำ",
        code: "1"
    },
    {
        id: "644",
        en: "honey",
        th: "น้ำผึ้ง",
        code: "1"
    },
    {
        id: "645",
        en: "independence",
        th: "ความเป็นอิสระ",
        code: "1"
    },
    {
        id: "646",
        en: "insect",
        th: "แมลง",
        code: "1"
    },
    {
        id: "647",
        en: "inspector",
        th: "สารวัตร",
        code: "1"
    },
    {
        id: "648",
        en: "ladder",
        th: "บันไดปีน",
        code: "1"
    },
    {
        id: "649",
        en: "profession",
        th: "วิชาชีพ",
        code: "1"
    },
    {
        id: "650",
        en: "professor",
        th: "ศาสตราจารย์",
        code: "1"
    },
    {
        id: "651",
        en: "reaction",
        th: "ปฏิกิริยา",
        code: "1"
    },
    {
        id: "652",
        en: "salad",
        th: "สลัด",
        code: "1"
    },
    {
        id: "653",
        en: "sister",
        th: "น้องสาว",
        code: "1"
    },
    {
        id: "654",
        en: "supermarket",
        th: "ซูเปอร์มาร์เก็ต",
        code: "1"
    },
    {
        id: "655",
        en: "tongue",
        th: "ลิ้น",
        code: "1"
    },
    {
        id: "656",
        en: "wedding",
        th: "งานแต่งงาน",
        code: "1"
    },
    {
        id: "657",
        en: "bottle",
        th: "ขวด",
        code: "1"
    },
    {
        id: "658",
        en: "candle",
        th: "เทียนไข",
        code: "1"
    },
    {
        id: "659",
        en: "clerk",
        th: "เสมียน",
        code: "2"
    },
    {
        id: "660",
        en: "cloud",
        th: "ก้อนเมฆ",
        code: "2"
    },
    {
        id: "661",
        en: "flower",
        th: "ดอกไม้",
        code: "1"
    },
    {
        id: "662",
        en: "grandfather",
        th: "ปู่",
        code: "1"
    },
    {
        id: "663",
        en: "knee",
        th: "เข่า",
        code: "1"
    },
    {
        id: "664",
        en: "lawyer",
        th: "ทนายความ",
        code: "1"
    },
    {
        id: "665",
        en: "leather",
        th: "หนัง",
        code: "1"
    },
    {
        id: "666",
        en: "mirror",
        th: "กระจกเงา",
        code: "1"
    },
    {
        id: "667",
        en: "neck",
        th: "คอ",
        code: "1"
    },
    {
        id: "668",
        en: "pension",
        th: "เงินบำนาญ",
        code: "1"
    },
    {
        id: "669",
        en: "purple",
        th: "สีม่วง",
        code: "1"
    },
    {
        id: "670",
        en: "skirt",
        th: "กระโปรง",
        code: "1"
    },
    {
        id: "671",
        en: "snow",
        th: "หิมะ",
        code: "1"
    },
    {
        id: "672",
        en: "stroke",
        th: "จังหวะ",
        code: "3"
    },
    {
        id: "673",
        en: "concentrate",
        th: "สมาธิ",
        code: "1"
    },
    {
        id: "674",
        en: "male",
        th: "ชาย",
        code: "1"
    },
    {
        id: "675",
        en: "shift",
        th: "กะ",
        code: "3"
    },
    {
        id: "676",
        en: "affair",
        th: "ธุระ",
        code: "1"
    },
    {
        id: "677",
        en: "analyst",
        th: "นักวิเคราะห์",
        code: "1"
    },
    {
        id: "678",
        en: "apple",
        th: "แอปเปิ้ล",
        code: "1"
    },
    {
        id: "679",
        en: "assignment",
        th: "งานที่มอบหมาย",
        code: "1"
    },
    {
        id: "680",
        en: "assistant",
        th: "ผู้ช่วย",
        code: "1"
    },
    {
        id: "681",
        en: "bathroom",
        th: "ห้องน้ำ",
        code: "1"
    },
    {
        id: "682",
        en: "bedroom",
        th: "ห้องนอน",
        code: "1"
    },
    {
        id: "683",
        en: "birthday",
        th: "วันเกิด",
        code: "1"
    },
    {
        id: "684",
        en: "cheek",
        th: "แก้ม",
        code: "1"
    },
    {
        id: "685",
        en: "consequence",
        th: "ผลที่ตามมา",
        code: "3"
    },
    {
        id: "686",
        en: "diamond",
        th: "เพชร",
        code: "1"
    },
    {
        id: "687",
        en: "ear",
        th: "หู",
        code: "1"
    },
    {
        id: "688",
        en: "friendship",
        th: "มิตรภาพ",
        code: "1"
    },
    {
        id: "689",
        en: "gene",
        th: "พันธุกรรม",
        code: "2"
    },
    {
        id: "690",
        en: "hat",
        th: "หมวก",
        code: "1"
    },
    {
        id: "691",
        en: "indication",
        th: "ข้อบ่งชี้",
        code: "1"
    },
    {
        id: "692",
        en: "intention",
        th: "เจตนา",
        code: "1"
    },
    {
        id: "693",
        en: "midnight",
        th: "เที่ยงคืน",
        code: "1"
    },
    {
        id: "694",
        en: "obligation",
        th: "ภาระผูกพัน",
        code: "1"
    },
    {
        id: "695",
        en: "passener",
        th: "ผู้โดยสาร",
        code: "1"
    },
    {
        id: "696",
        en: "pizza",
        th: "พิซซ่า",
        code: "1"
    },
    {
        id: "697",
        en: "poet",
        th: "กวี",
        code: "1"
    },
    {
        id: "698",
        en: "pollution",
        th: "มลพิษ",
        code: "1"
    },
    {
        id: "699",
        en: "reputation",
        th: "ชื่อเสียง",
        code: "1"
    },
    {
        id: "700",
        en: "shirt",
        th: "เสื้อ",
        code: "1"
    },
    {
        id: "701",
        en: "speaker",
        th: "ลำโพง",
        code: "1"
    },
    {
        id: "702",
        en: "stranger",
        th: "คนthกหน้า",
        code: "1"
    },
    {
        id: "703",
        en: "tale",
        th: "เรื่องเล่า",
        code: "2"
    },
    {
        id: "704",
        en: "trainer",
        th: "ผู้ฝึกสอน",
        code: "2"
    },
    {
        id: "705",
        en: "uncle",
        th: "ลุง",
        code: "1"
    },
    {
        id: "706",
        en: "camp",
        th: "ค่าย",
        code: "1"
    },
    {
        id: "707",
        en: "candy",
        th: "ลูกอม",
        code: "1"
    },
    {
        id: "708",
        en: "carpet",
        th: "พรม",
        code: "1"
    },
    {
        id: "709",
        en: "cat",
        th: "แมว",
        code: "1"
    },
    {
        id: "710",
        en: "channel",
        th: "ช่อง",
        code: "1"
    },
    {
        id: "711",
        en: "clock",
        th: "นาฬิกา",
        code: "1"
    },
    {
        id: "712",
        en: "cow",
        th: "วัว",
        code: "1"
    },
    {
        id: "713",
        en: "enineer",
        th: "วิศวกร",
        code: "1"
    },
    {
        id: "714",
        en: "entrance",
        th: "ทางเข้า",
        code: "1"
    },
    {
        id: "715",
        en: "grass",
        th: "หญ้า",
        code: "1"
    },
    {
        id: "716",
        en: "island",
        th: "เกาะ",
        code: "1"
    },
    {
        id: "717",
        en: "joke",
        th: "มุกตลก",
        code: "2"
    },
    {
        id: "718",
        en: "jury",
        th: "คณะลูกขุน",
        code: "1"
    },
    {
        id: "719",
        en: "leg",
        th: "ขา",
        code: "1"
    },
    {
        id: "720",
        en: "lip",
        th: "ริมฝีปาก",
        code: "1"
    },
    {
        id: "721",
        en: "nerve",
        th: "เส้นประสาท",
        code: "1"
    },
    {
        id: "722",
        en: "passage",
        th: "ทางเดิน",
        code: "1"
    },
    {
        id: "723",
        en: "pen",
        th: "ปากกา",
        code: "1"
    },
    {
        id: "724",
        en: "priest",
        th: "นักบวช",
        code: "1"
    },
    {
        id: "725",
        en: "promise",
        th: "สัญญา",
        code: "1"
    },
    {
        id: "726",
        en: "resident",
        th: "ผู้อยู่อาศัย",
        code: "1"
    },
    {
        id: "727",
        en: "resort",
        th: "รีสอร์ท",
        code: "1"
    },
    {
        id: "728",
        en: "ring",
        th: "แหวน",
        code: "1"
    },
    {
        id: "729",
        en: "roof",
        th: "หลังคา",
        code: "1"
    },
    {
        id: "730",
        en: "rope",
        th: "เชือก",
        code: "1"
    },
    {
        id: "731",
        en: "script",
        th: "บทละคร",
        code: "2"
    },
    {
        id: "732",
        en: "sock",
        th: "ถุงเท้า",
        code: "1"
    },
    {
        id: "733",
        en: "station",
        th: "สถานี",
        code: "1"
    },
    {
        id: "734",
        en: "toe",
        th: "นิ้วเท้า",
        code: "1"
    },
    {
        id: "735",
        en: "tower",
        th: "หอคอย",
        code: "1"
    },
    {
        id: "736",
        en: "truck",
        th: "รถบรรทุก",
        code: "1"
    },
    {
        id: "737",
        en: "witness",
        th: "พยาน",
        code: "1"
    },
    {
        id: "738",
        en: "bear",
        th: "หมี",
        code: "1"
    },
    {
        id: "739",
        en: "female",
        th: "เพศหญิง",
        code: "2"
    },
    {
        id: "740",
        en: "punch",
        th: "หมัด",
        code: "1"
    },
    {
        id: "741",
        en: "tomorrow",
        th: "พรุ่งนี้",
        code: "1"
    },
    {
        id: "742",
        en: "yesterday",
        th: "เมื่อวาน",
        code: "1"
    },
    {
        id: "743",
        en: "potato",
        th: "มันฝรั่ง",
        code: "2"
    }
]
