import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const ifAuthenticated = async (to, from, next) => {
  if (store.state.token) {
    next();
  } else {
    window.location.href = 'https://mindanalytica.com/';
  }
};

const ifAdminUp = async (to, from, next) => {
  if (store.state.token && store.state.user && store.state.surveyType == "casual" && (store.state.user.type === "super-admin" || store.state.user.type === "admin")) {
    next();
  } else {
    next('/');
  }
};

const ifSuperAdminUp = async (to, from, next) => {
  if (store.state.token && store.state.user && store.state.surveyType == "casual" && (store.state.user.type === "super-admin")) {
    next();
  } else {
    next('/');
  }
};

let router = new Router({
  mode: "history",
  routes: [
    { path: "*", component: () => import("@/views/ErrorScreen") },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/Home"),
      beforeEnter: ifAuthenticated
    },

    {
      path: "/test/:id",
      name: "test",
      component: () => import("@/views/Test"),
      beforeEnter: ifAuthenticated
    },
    {
      path: "/response/report",
      name: "responseReport",
      component: () => import("@/views/ResponseReport"),
      beforeEnter: ifAdminUp
    },
    {
      path: "/question/report",
      name: "questionReport",
      component: () => import("@/views/QuestionReport"),
      beforeEnter: ifAdminUp
    },
    {
      path: "/statistics/report",
      name: "statisticsReport",
      component: () => import("@/views/StatisticsReport"),
      beforeEnter: ifAdminUp
    },
    {
      path: "/statistics/period/report",
      name: "statisticsPeriodReport",
      component: () => import("@/views/StatisticsPeriodReport"),
      beforeEnter: ifAdminUp
    },
    {
      path: "/setting",
      name: "setting",
      component: () => import("@/views/Setting"),
      beforeEnter: ifSuperAdminUp
    },
    {
      path: "/signin/:token",
      name: "signin",
      component: () => import("@/views/Signin"),
    },

  ],
});

export default router;
