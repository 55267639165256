import initState from "./initState";

export default {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
  },
  SET_MODE(state, payload) {
    state.mode = payload;
  },

  SET_SURVEY_TYPE(state, payload) {
    state.surveyType = payload;
  },
  SET_STEPS(state, payload) {
    state.steps = payload;
  },
  SET_DISCONNECT(state, payload) {
    state.disconnect = payload;
  },
  SIGNOUT: (state) => {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
