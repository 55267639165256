import { HTTP } from "./axios";
import store from "../store";
export default {
  mounted() {
    HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  },
  computed: {
    token() {
      return store.state.token;
    },
    user() {
      return store.state.user;
    },
  },
  methods: {
    signOut() {
      delete HTTP.defaults.headers.common.Authorization;
      this.$store.commit('SIGNOUT');
      window.localStorage.removeItem('test-probability');
      window.location.href = 'https://mindanalytica.com/';
    }
  },
};
