<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";

export default {
  name: "App",
  sockets: {
    connect() {
      console.log("connect");
    },

    logout(data) {
      if (data.userId === this.user.id) {
        this.signOut();
      }
    },
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    surveyType() {
      return this.$store.state.surveyType;
    },
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    isIdle(idle) {
      if (this.surveyType === "period") {
        if (idle) {
          this.myIntervalIdle = setInterval(() => {
            this.idleTime++;
          }, 1000);
          this.offline(true);
        } else {
          this.idleTime = 0;
          clearInterval(this.myIntervalIdle);
          this.online();
        }
      }
    },
    idleTime(sec) {
      if (sec >= 60 * 30.2) {
        clearInterval(this.myIntervalIdle);
        this.checkTestPeriod();
      }
    },
  },
  data() {
    return {
      idleTime: 0,
      myIntervalIdle: null,
    };
  },
  created() {
    window.addEventListener(
      "beforeunload",
      () => {
        if (this.surveyType) {
          this.offline();
        }
      },
      false
    );
  },
  mounted() {
    if (this.surveyType) {
      this.online();
      this.checkTestPeriod();
    }
  },
  methods: {
    async offline() {
      try {
        let obj = {
          surveyType: this.surveyType,
        };
        if (this.token) {
          await HTTP.put(`/log/test/periods/offline`, obj);
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async online() {
      try {
        let obj = {
          surveyType: this.surveyType,
        };
        if (this.token) {
          await HTTP.put(`/log/test/periods/online`, obj);
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async checkTestPeriod() {
      try {
        let res = await HTTP.get(`/log/test/periods/by/${this.surveyType}`);

        if (res.data.success) {
          if (!res.data.result) {
            this.$socket.emit("endGame", {
              userId: this.user.id,
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
