import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/styles/index.scss";
import VueFullscreen from "vue-fullscreen";
import Auth from "@/service/auth";
import i18nMessages from "@/service/i18nMessages";

import "@/service/other";

import locale from "element-ui/lib/locale/lang/en";
import VueI18n from 'vue-i18n'
import IdleVue from "idle-vue";

import VueSocketio from "vue-socket.io";
import io from "socket.io-client";
import device from "vue-device-detector"

import moment from "moment";
import momentTH from "moment/src/locale/th";

import numeral from "numeral";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faPlay, faMinus, faRotateLeft, faDeleteLeft, faCheckCircle, faLock, faCircleXmark, faXmark, faHeart, faCircleCheck, faTrash, faSquareCheck, faGamepad, faPlusMinus, faChartSimple, faBars } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark as faRegularCircleXmark, faCircleCheck as faRegularCircleCheck } from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faPlay, faMinus, faRotateLeft, faDeleteLeft, faCheckCircle, faLock, faCircleXmark, faCircleCheck, faXmark, faHeart, faTrash, faRegularCircleXmark, faRegularCircleCheck, faSquareCheck, faGamepad, faPlusMinus, faChartSimple, faBars)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueI18n);

Vue.config.productionTip = false;

Vue.filter("commaDec2", (value) => {
  return numeral(value).format("0,0.00");
});


Vue.filter("comma", (value) => {
  return numeral(value).format("0,0");
});

Vue.filter("dateTimeTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {

    res = `${moment(date).locale("th", momentTH).add(543, "y").format('ll')} ${moment(date).locale("th", momentTH).format('LTS น.')}`
  }
  return res;
})


Vue.filter("dateTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {

    res = `${moment(date).locale("th", momentTH).add(543, "y").format('ll')}`
  }
  return res;
});

Vue.filter("timeTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {


    res = `${moment(date).locale("th", momentTH).format('LTS น.')}`
  }
  return res;
});


Vue.filter("dateEn", (date) => {

  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {


    res = moment().format("LLL");
  }
  return res;
});


// Ready translated locale messages
const messages = i18nMessages

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'th', // set locale
  messages, // set locale messages
})

const eventsHub = new Vue();

Vue.use(
  new VueSocketio({
    upgrade: false,
    transports: ["websocket"],
    connection: io(process.env.VUE_APP_BASE_URL),
    options: { timeout: 1000 * 60 * 60, autoConnect: false },
  })
);

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000,
  startAtIdle: false,
});



Vue.mixin(Auth);
Vue.use(VueFullscreen);
Vue.use(ElementUI, {
  locale,
});

Vue.use(device)

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
