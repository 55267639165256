export default [
    { id: "1", outershape: 3, innershape: 3 },
    { id: "2", outershape: 4, innershape: 3 },
    { id: "3", outershape: 6, innershape: 3 },
    { id: "4", outershape: 360, innershape: 3 },
    { id: "5", outershape: 3, innershape: 4 },
    { id: "6", outershape: 4, innershape: 4 },
    { id: "7", outershape: 6, innershape: 4 },
    { id: "8", outershape: 360, innershape: 4 },
    { id: "9", outershape: 3, innershape: 6 },
    { id: "10", outershape: 4, innershape: 6 },
    { id: "11", outershape: 6, innershape: 6 },
    { id: "12", outershape: 360, innershape: 6 },
    { id: "13", outershape: 3, innershape: 360 },
    { id: "14", outershape: 4, innershape: 360 },
    { id: "15", outershape: 6, innershape: 360 },
    { id: "16", outershape: 360, innershape: 360 },
]