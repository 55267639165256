export default {
    th: {
        message: {
            titleHome: 'แบบทดสอบ',
            menuProfile: "โปรไฟล์",
            responseReport: "รายงานผลการตอบ",
            questionReport: "รายงานข้อคําถาม",
            statisticsReport: "สถิติของแบบทดสอบ",
            statisticsPeriodReport: "สถิติของการทดสอบแบบกําหนดเวลา",
            setting: "ตั้งค่าระบบ",
            logout: "ออกจากระบบ",
            unitSecond: "วินาที",
            tileState: "ทำแบบทดสอบแล้ว"
        },
        test: {
            level: "เลเวล",
            type: "ประเภท",
            result: "ผลการทดสอบ",
            description: "คำอธิบาย",
            btnHowto: "ดูวิธีการทดสอบ",
            btnStartGame: "เริ่มแบบทดสอบ",
            btnStartTest: "ทำแบบทดสอบ",
            btnSendDelete: "ลบ",
            btnSendReset: "ล้าง",
            btnSendAnswer: "ส่งคำตอบ",
            btnYes: "ใช่",
            btnNo: "ไม่ใช่",
            optionMode1: "ทั่วไป",
            optionMode2: "เปิดทุกด่านสำหรับทดสอบ",
            endGameTitle: "จบแบบทดสอบ",
            endGameLevel: "เลเวลที่ทำได้",
            endGameSaveAgain: "บันทึกอีกครั้ง",
            endGameExit: "ออก",
            lockScreenTitle: "แจ้งเตือน",
            lockScreenVertically: "กรุณาใช้งานในแนวตั้ง",
        },
        tutorial: {
            btnBack: "ย้อนกลับ",
            btnSkip: "ข้าม",
            btnNext: "ถัดไป",
        },
        home: {
            remainingTime: "เวลาที่เหลือ",
            timeSpent: "เวลาที่ใช้ไป",
            btnViewScore: "ดูผลการทดสอบ",
            descriptionViewScore: "ต้องเล่นถึง 3 แบบทดสอบจนได้เลเวลสูงสุดก่อนถึงจะดูผลการทดสอบได้",
            maxLevel: "ระดับสูงสุดที่ทำได้",
            viewTest: "เข้าสู่แบบทดสอบ",
            prefixTotal: "จำนวน",
            suffixTotal: "แบบทดสอบ",
        },
        breadcrums: {
            home: "หน้าแรก"
        },
        tutorialBeepCode: {
            pressToPlay: "กดเพื่อเล่น",
            pressYourAnswer: "กดคำตอบของคุณ",
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบกรุณากดปุ่ม Play เพื่อฟังเสียงโดยจะมีเสียงอยู่ 2 รูปแบบคือเสียงสั้นและเสียงยาว",
            step2: "เมื่อระบบทำการเล่นเสียงครบแล้วจะเข้าสู่หน้าตอบคำถาม คุณต้องเลือกตอบคำถามตามเสียงที่ได้ยิน",
            step3: "สัญลักษณ์ขีดสั้นคือเสียงสั้น ส่วนสัญลักษณ์ขีดยาวคือเสียงยาว",
            step4: "เมื่อคุณกดเลือกคำตอบตามเสียงที่ได้ยินครบแล้วให้กดปุ่ม “ส่งคำตอบ”",
            step5: "เมื่อคุณตอบผิดบล็อกคำตอบจะเปลี่ยนเป็นสีแดง",
            step6: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step7: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step8: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step9: "เมื่อคุณตอบถูกจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialColorSpan: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบจะทำการแสดงบล็อกสีที่เป็นคำตอบทั้งหมด",
            step2: "เมื่อคุณจำบล็อกสีทั้งหมดเรียบร้อยแล้วกดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "ถ้าหากผ่านไป 10 วินาทีและยังไม่ได้กดปุ่ม “เริ่มแบบทดสอบ” จะเข้าสู่การทดสอบโดยอัตโนมัติ",
            step4: "เมื่อกดปุ่ม “ส่งคำตอบ” จะแสดงแป้นสีตัวเลือกคำตอบ",
            step5: "ขอให้คุณกดเลือกสีทั้งหมดให้ตรงกับที่โจทย์แสดงไปในตอนแรก",
            step6: "เมื่อคุณตอบผิดบล็อกสีจะเปลี่ยนเป็นสีแดง",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step8: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step9: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบถูกจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialDigitSpanBackward: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบระบบจะทำการแสดงตัวเลขตามลำดับ (ลำดับที่ 1)",
            step2: "เมื่อคุณเข้าสู่การทดสอบระบบจะทำการแสดงตัวเลขตามลำดับ (ลำดับที่ 2)",
            step3: "เมื่อแสดงตัวเลขตามลำดับครบแล้ว ระบบจะแสดงแป้นตัวเลข",
            step4: "ขอให้คุณกดเลือกตัวเลขให้ตรงตามลำดับที่แสดงโดยเรียงจากลำดับสุดท้ายไปลำดับแรก",
            step5: "เมื่อกรอกตัวเลขเรียบร้อยแล้วให้ทำการกดส่งคำตอบ",
            step6: "เมื่อคุณตอบผิดช่องกรอกตัวเลขจะเปลี่ยนเป็นสีแดง",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step8: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step9: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialDigitSpanForward: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบระบบจะทำการแสดงตัวเลขตามลำดับ (ลำดับที่ 1)",
            step2: "เมื่อคุณเข้าสู่การทดสอบระบบจะทำการแสดงตัวเลขตามลำดับ (ลำดับที่ 2)",
            step3: "เมื่อแสดงตัวเลขตามลำดับครบแล้ว ระบบจะแสดงแป้นตัวเลข",
            step4: "ขอให้คุณกดเลือกตัวเลขให้ตรงตามลำดับที่แสดงโดยเรียงจากลำดับแรกไปลำดับสุดท้าย",
            step5: "เมื่อกรอกตัวเลขเรียบร้อยแล้วให้ทำการกดส่งคำตอบ",
            step6: "เมื่อคุณตอบผิดช่องกรอกตัวเลขจะเปลี่ยนเป็นสีแดง",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step8: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step9: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialNumberSequences: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการแสดงเลขของแต่ละบล็อก",
            step2: "เมื่อคุณจำตำแหน่งบล็อกและตัวเลขเรียบร้อยแล้ว กดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "ถ้าหากผ่านไป 10 วินาทีและยังไม่ได้กดปุ่ม “เริ่มแบบทดสอบ” จะเข้าสู่การทดสอบโดยอัตโนมัติ",
            step4: "เมื่อกดปุ่ม “เริ่มแบบทดสอบ” บล็อกทั้งหมดจะกลับไปเริ่มต้นตัวเลขจะหายไป",
            step5: "ขอให้คุณกดบล็อกสี่เหลี่ยมตามลำดับของเลขที่แสดงในบล็อก",
            step6: "เมื่อคุณตอบผิดคำตอบของคุณจะเปลี่ยนเป็นสีแดง",
            step7: "เมื่อคุณตอบถูกบล็อกจะแสดงตัวเลข",
            step8: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step9: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step10: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step11: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialPairassociateslearning: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการแสดงรูปภาพที่เป็นคำตอบทั้งหมด",
            step2: "เมื่อคุณจำรูปภาพทั้งหมดร้อยแล้ว กดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "ถ้าหากผ่านไป 10 วินาทีและยังไม่ได้กดปุ่ม “เริ่มแบบทดสอบ” จะเข้าสู่การทดสอบโดยอัตโนมัติ",
            step4: "เมื่อกดปุ่ม “เริ่มแบบทดสอบ” รูปภาพคำตอบทั้งหมดจะถูกซ่อนและแสดงรูปภาพโจทย์",
            step5: "ขอให้คุณกดเลือกบล็อกที่มีรูปภาพที่ตรงกับรูปภาพโจทย์ที่แสดงอยู่",
            step6: "เมื่อคุณตอบผิดบล็อกโจทย์จะเปลี่ยนเป็นสีแดง",
            step7: "เมื่อคุณตอบถูกบล็อกจะแสดงรูปภาพ",
            step8: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step9: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step10: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step11: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialSpatialSpanColorSequence: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการเปลี่ยนสีครั้งละ 1 บล็อก",
            step2: "บล็อกจะเปลี่ยนสีเหลืองหรือสีเขียวโดยการสุ่ม",
            step3: "ขอให้คุณจำ ตำแหน่ง สี ลำดับ ในการปรากฎของบล็อกที่เปลี่ยนไป",
            step4: "เมื่อบล็อกเปลี่ยนสีครบแล้วจะทำการสุ่มสีเหลืองหรือสีเขียวให้คุณตอบก่อน",
            step5: "คำตอบที่ถูกต้องคุณจะต้องตอบบล็อกสีเขียวตามลำดับก่อนหลัง",
            step6: "หากคุณกดบล็อกตามลำดับของสีที่โจทย์ให้ตอบไม่ถูก บล็อกจะเปลี่ยนสีแดง",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step9: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step8: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialSpatialSpanSequence: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการเปลี่ยนสีครั้งละ 1 บล็อก",
            step2: "ขอให้คุณจำ ตำแหน่ง สี ลำดับ ในการปรากฎของบล็อกที่เปลี่ยนไป",
            step3: "เมื่อบล็อกเปลี่ยนสีครบแล้ว คุณจะสามารถเริ่มตอบได้",
            step4: "ขอให้คุณกดบล็อกสี่เหลี่ยมที่เปลี่ยนสีตามลำดับที่โจทย์แสดง",
            step5: "เมื่อคุณตอบผิดคำตอบของคุณจะเปลี่ยนเป็นสีแดง",
            step6: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step7: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step8: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step9: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialSpatialSpan: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการเปลี่ยนสี",
            step2: "เมื่อคุณจำตำแหน่งบล็อกที่เปลี่ยนสีเรียบร้อยแล้ว กดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "ถ้าหากผ่านไป 10 วินาทีและยังไม่ได้กดปุ่ม “เริ่มแบบทดสอบ” จะเข้าสู่การทดสอบโดยอัตโนมัติ",
            step4: "เมื่อกดปุ่ม “เริ่มแบบทดสอบ” บล็อกทั้งหมดจะกลับไปสีเริ่มต้น",
            step5: "ขอให้คุณกดบล็อกสี่เหลี่ยมที่เปลี่ยนสีไปก่อนหน้านี้ โดยไม่ต้องเรียงลำดับ",
            step6: "เมื่อคุณตอบผิดคำตอบของคุณจะเปลี่ยนเป็นสีแดง",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step8: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step9: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialSpatialSpanWorkingMemory: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบบล็อกด้านล่างจะทำการเปลี่ยนสี",
            step2: "เมื่อคุณจำตำแหน่งบล็อกที่เปลี่ยนสีเรียบร้อยแล้ว กดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "ถ้าหากผ่านไป 10 วินาทีและยังไม่ได้กดปุ่ม “เริ่มแบบทดสอบ” จะเข้าสู่การทดสอบโดยอัตโนมัติ",
            step4: "เมื่อกดปุ่ม “เริ่มแบบทดสอบ” บล็อกทั้งหมดจะกลับไปสีเริ่มต้น",
            step5: "ขอให้คุณกดบล็อกสี่เหลี่ยมที่เปลี่ยนสีไปก่อนหน้านี้ โดยไม่ต้องเรียงลำดับ",
            step6: "เมื่อคุณตอบผิดคำตอบของคุณจะเปลี่ยนเป็นสีแดง",
            step7: "เมื่อคุณกดบล็อกแล้วเจอ วัตถุให้ทำการกดบล็อกด้านล่างเพื่อทำการเก็บวัตถุ",
            step8: "เมื่อเก็บวัตถุแล้วจะแสดงวัตถุและจำนวนที่เก็บได้",
            step9: "หากไม่ทำการเก็บวัตถุแล้วกดบล็อกอื่นจะถือว่าตอบผิด",
            step10: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step11: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step12: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step13: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        tutorialWordRecognition: {
            step0: "เริ่มสาธิตวิธีการทดสอบ",
            step1: "เมื่อคุณเข้าสู่การทดสอบระบบจะแสดงคำศัพท์ที่เป็นโจทย์",
            step2: "เมื่อคุณจำคำศัพท์ทั้งหมดร้อยแล้ว กดปุ่ม “เริ่มแบบทดสอบ” เพื่อเข้าสู่การทดสอบ",
            step3: "เมื่อกดปุ่ม “เริ่มแบบทดสอบ” จะแสดงคำศัพท์ที่เป็นคำตอบ",
            step4: "ขอให้คุณกดเลือกปุ่ม “ใช่” หากมีคำศัพท์นี้ในโจทย์ที่แสดงไปตอนต้น หากไม่มีคำศัพท์นี้ให้กดเลือกปุ่ม “ไม่ใช่”",
            step5: "เมื่อคุณตอบผิดคำศัพท์จะเปลี่ยนเป็นสีแดง",
            step6: "เมื่อคุณตอบถูกจะแสดงคำตอบข้อถัดไป",
            step7: "คุณสามารถตอบผิดได้ทั้งหมด 3 ครั้งก่อนการทดสอบจะยุติลง",
            step8: "เมื่อตอบผิดโจทย์จะสุ่มใหม่",
            step9: "โดยที่คุณจะต้องเริ่มใหม่ตั้งแต่ต้น",
            step10: "เมื่อคุณตอบครบแล้วจะเข้าสู่ level ถัดไปโดยอัตโนมัติ",
        },
        responseReport: {
            findGameSessionId: "ค้นหา GameSession_ID",
            findSessionId: "ค้นหา Session_ID",
            pleaseSelectGameSessionFinished: "กรุณาเลือก GameSessionFinished",
            pleaseSelectDevice: "กรุณาเลือก Device",
            findSessionType: "ค้นหา SessionType",
            pleaseSelectLevel: "กรุณาเลือก Level",
            pleaseSelectUser: "กรุณาเลือกผู้ใช้",
            timeStartStimuliTo: "ถึง",
            timeStartStimuliStart: "เวลาที่เริ่มทำ",
            timeStartStimuliEnd: "เวลาที่สิ้นสุด",
            pleaseSelectSortType: "กรุณาเลือกรูปแบบการเรียงข้อมูล",
            sortText: "เรียงตาม",
            level: "เลเวล",
            gameSessionFinishedOption1: "ได้เลเวลสูงสุด",
            gameSessionFinishedOption2: "ไม่ได้เลเวลสูงสุด",
        },
        questionReport: {
            pleaseSelectSessionType: "กรุณาเลือกประเภทแบบสอบถาม",
            pleaseSelectTest: "กรุณาเลือกแบบทดสอบ",
            pleaseSelectLevel: "กรุณาเลือกเลเวล",
            number: "ลำดับ",
            name: "รูปแบบ",
            totalUse: "จํานวนครั้งที่ข้อนี้ถูกใช้ทดสอบ",
            totalPass: "จํานวนผู้ใช้ที่ผ่านการทดสอบข้อนี้",
            percent: "เปอร์เซนต์การตอบถูก",
            avg: "ค่าเฉลี่ยของ Durationresponse",
            sd: "ส่วนเบี่ยงเบนมาตรฐานของ Durationresponse",
            percentPass: "เปอร์เซนต์การตอบถูก(หากเลเวลนั้นผ่าน)",
            question: "คำถาม",
            answer: "คำตอบ",
        },
        statisticsReport: {
            findSessionType: "ค้นหา SessionType",
            pleaseSelectTest: "กรุณาเลือกแบบทดสอบ",
            pleaseSelectLevel: "กรุณาเลือกเลเวล",
            playTotal: "จำนวนที่ทดสอบ",
            playSucceed: "จำนวนที่ทดสอบสำเร็จ",
            avg: "ค่าเฉลี่ย",
            sd: "ส่วนเบี่ยงเบนมาตราฐาน",
            avgSDDescription: "(เวลาที่ใช้ในการเล่นแบบทดสอบ จนทดสอบสําเร็จ)",
            tableName: "ตารางความถี่สะสมของคนที่ทำสูงสุดถึงเลเวลนี้",
            level: "เลเวล",
            frequency: "ความถี่",
            cumulativeFrequency: "ความถี่สะสม",
            percentFrequency: "เปอร์เซนต์ความถี่",
            percentCumulativeFrequency: "เปอร์เซนต์ความถี่สะสม",
            tableAVG: "ค่าเฉลี่ยของเวลาที่ใช้ทดสอบในเลเวล",
            tableSD: "ส่วนเบี่ยงเบนมาตรฐานของเวลาที่ใช้ทดสอบในเลเวล",
            mobile: "มือถือ",
            tablet: "แท็บเล็ต",
            computer: "คอมพิวเตอร์",
        },
        statisticsPeriodReport: {
            totalTest: "คนที่ initiate test",
            totalTestSuccess: "คนที่ทำสำเร็จ แต่อาจมี null",
            totalTestFail: "คนที่มี null จะเสร็จหรือไม่เสร็จก็ได้",
            playPercentSeries: "เปอร์เซนต์การทดสอบ",
            mobile: "มือถือ",
            tablet: "แท็บเล็ต",
            computer: "คอมพิวเตอร์",
            avgSD: "ค่าเฉลี่ยและส่วนเบี่ยงเบนมาตรฐานของเวลาที่ใช้ในแบบกำหนดเวลา",
            sd: "ส่วนเบี่ยงเบนมาตรฐาน",
            avg: "ค่าเฉลี่ย",
        },
        setting: {
            tableTest: "แบบทดสอบ",
            name: "ชื่อ",
            casual: "แบบสบายๆ",
            period: "แบบกำหนดเวลา",
            openToPlayInCasualMode: "เปิดให้เล่นในแบบสบายๆ",
            openToPlayInPeriodMode: "เปิดให้เล่นในแบบกำหนดเวลา",

        }
    },
    en: {
        message: {
            titleHome: 'Test',
            menuProfile: "Profile",
            responseReport: "Response report",
            questionReport: "Response question",
            statisticsReport: "Statistics report",
            statisticsPeriodReport: "Statistics Period report",
            setting: "Setting",
            logout: "Log Out",
            unitSecond: "Second",
            tileState: "Test done"

        },
        test: {
            level: "LEVEL",
            type: "Type",
            result: "Result",
            description: "Description",
            btnHowto: "Walkthroughs",
            btnStartGame: "Start",
            btnStartTest: "Start test",
            btnSendDelete: "Delete",
            btnSendReset: "Reset",
            btnSendAnswer: "Submit",
            btnYes: "Yes",
            btnNo: "No",
            optionMode1: "General",
            optionMode2: "Open all",
            endGameTitle: "End test",
            endGameLevel: "Max level",
            endGameSaveAgain: "Save again",
            endGameExit: "Exit",
            lockScreenTitle: "Warning",
            lockScreenVertically: "Please use it vertically.",

        },
        tutorial: {
            btnBack: "Back",
            btnSkip: "Skip",
            btnNext: "Next",
        },
        home: {
            remainingTime: "Remaining time",
            timeSpent: "Time spent",
            btnViewScore: "View test results",
            descriptionViewScore: "You must play 3 quizzes to reach the max level before you can view the quiz results.",
            maxLevel: "Max level",
            viewTest: "Go to test",
            prefixTotal: "Total",
            suffixTotal: "tests",
        },
        breadcrums: {
            home: "Home"
        },
        tutorialBeepCode: {
            pressToPlay: "Press to play",
            pressYourAnswer: "Press your answer",
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, please press the Play button to listen to the sound, there will be 2 types of sound, a short sound and a long sound.",
            step2: "After the audio playback is completed, you will enter the question page. You must select the question based on the sound you hear.",
            step3: "The dash symbol is a short sound. The dash symbol is a long sound.",
            step4: "When you hear all the answers, click “Send Answers”.",
            step5: "When you answer incorrectly, the answer block will turn red.",
            step6: "You can get a total of 3 wrong answers before the test is over.",
            step7: "When answering incorrectly, the question will be randomized again.",
            step8: "You must start from scratch.",
            step9: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialColorSpan: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test it will display all the answer colored blocks.",
            step2: "After remembering all the color blocks, press the “Start Test” button to enter the test.",
            step3: "If 10 seconds have passed and the button has not been pressed “Start Test” will automatically enter the test.",
            step4: "Pressing the “Send Response” button will display the answer options color pad.",
            step5: "Ask you to select all the colors that match what the problem originally showed.",
            step6: "When you answer wrong blocks color will turn red.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialDigitSpanBackward: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, the number will be displayed in order (1st order).",
            step2: "When you enter the test, the number will be displayed in order (2st order).",
            step3: "When all numbers are displayed in sequence The system will display a numeric keypad.",
            step4: "Ask you to press the numbers in the order shown, from last to first.",
            step5: "After filling in the numbers, click 'Send Answer'.",
            step6: "When you answer wrong blocks color will turn red.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialDigitSpanForward: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, the number will be displayed in order (1st order).",
            step2: "When you enter the test, the number will be displayed in order (2st order).",
            step3: "When all numbers are displayed in sequence The system will display a numeric keypad.",
            step4: "Ask you to press the numbers in the order shown, from first to last.",
            step5: "After filling in the numbers, click 'Send Answer'.",
            step6: "When you answer wrong blocks color will turn red.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialNumberSequences: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test block below, it will display the number of each block.",
            step2: "After successfully remembering the blocks and numbers, press the “Start Test” button to enter the test.",
            step3: "If 10 seconds have passed and the button has not been pressed “Start Test” will automatically enter the test.",
            step4: "When the “Start Test” button is pressed, all the blocks will return to the beginning, the numbers will disappear.",
            step5: "Asks you to press the square blocks in the order of the number shown in the block.",
            step6: "When you answer wrong blocks color will turn red.",
            step7: "When you answer blocked it will show numbers.",
            step8: "You can get a total of 3 wrong answers before the test is over.",
            step9: "When answering incorrectly, the question will be randomized again.",
            step10: "You must start from scratch.",
            step11: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialPairassociateslearning: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test block below it will display a picture of all the answers.",
            step2: "After remembering all hundreds of images, press the “Start Test” button to enter the test.",
            step3: "If 10 seconds have passed and the button has not been pressed “Start Test” will automatically enter the test.",
            step4: "When you press the “Start Test” button, all answer images will be hidden and the question image will be displayed.",
            step5: "You are asked to click on the blocks that have pictures that match the displayed problem picture.",
            step6: "When you answer wrong blocks color will turn red.",
            step7: "When you answer blocked it will show a picture.",
            step8: "You can get a total of 3 wrong answers before the test is over.",
            step9: "When answering incorrectly, the question will be randomized again.",
            step10: "You must start from scratch.",
            step11: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialSpatialSpanColorSequence: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, the blocks below will change color 1 block at a time.",
            step2: "Blocks will turn yellow or green at random.",
            step3: "Please remember to change the position, color, and order of the blocks.",
            step4: "When the blocks change color, it will random yellow or green for you to answer first.",
            step5: "Correct answer you must answer the green block in first-come order.",
            step6: "If you hit the blocks in the order of the given color, the answer is incorrect. The block will turn red.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialSpatialSpanSequence: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, the blocks below will change color 1 block at a time.",
            step2: "Please remember to change the position, color, and order of the blocks.",
            step3: "When the blocks change color completely You will be able to start answering.",
            step4: "Please change the color of the squares in the order shown in the question.",
            step5: "When you answer wrong, your answer will turn red.",
            step6: "You can get a total of 3 wrong answers before the test is over.",
            step7: "When answering incorrectly, the question will be randomized again.",
            step8: "You must start from scratch.",
            step9: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialSpatialSpan: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test the bottom block will change color.",
            step2: "After remembering the position of the color change block, press the “Start Test” button to enter the test.",
            step3: "If 10 seconds have passed and the button has not been pressed “Start Test” will automatically enter the test.",
            step4: "When the “Start Test” button is pressed, all blocks will return to their default colors.",
            step5: "Asks you to hit a square block that previously changed color. without sorting",
            step6: "When you answer wrong, your answer will turn red.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialSpatialSpanWorkingMemory: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test the bottom block will change color.",
            step2: "After remembering the position of the color change block, press the “Start Test” button to enter the test.",
            step3: "If 10 seconds have passed and the button has not been pressed “Start Test” will automatically enter the test.",
            step4: "When the “Start Test” button is pressed, all blocks will return to their default colors.",
            step5: "Asks you to hit a square block that previously changed color. without sorting",
            step6: "When you answer wrong, your answer will turn red.",
            step7: "When you hit block and found Object, press the bottom block to collect the object.",
            step8: "When an object is collected, it will display the object and the number of items that can be collected.",
            step9: "If you don't collect the object and press another block, it will be considered wrong answer.",
            step10: "You can get a total of 3 wrong answers before the test is over.",
            step11: "When answering incorrectly, the question will be randomized again.",
            step12: "You must start from scratch.",
            step13: "When you answer correctly, you will automatically go to the next level.",
        },
        tutorialWordRecognition: {
            step0: "Start demonstrating testing methods",
            step1: "When you enter the test, the system will display the vocabulary that is the problem.",
            step2: "After remembering all words, press the “Start Test” button to enter the test.",
            step3: "Pressing the button “Start Test” will show the word that is the answer.",
            step4: "Ask you to press the “yes” button if there is a word in the problem shown at the beginning.If this word does not exist, press the “No” button.",
            step5: "When you answer wrong, your answer will turn red.",
            step6: "When you answer correctly it will show the next answer.",
            step7: "You can get a total of 3 wrong answers before the test is over.",
            step8: "When answering incorrectly, the question will be randomized again.",
            step9: "You must start from scratch.",
            step10: "When you answer correctly, you will automatically go to the next level.",
        },
        responseReport: {
            findGameSessionId: "Find GameSession_ID",
            findSessionId: "Find Session_ID",
            pleaseSelectGameSessionFinished: "Please select GameSessionFinished",
            pleaseSelectDevice: "Please select Device",
            findSessionType: "Find SessionType",
            pleaseSelectLevel: "Please select Level",
            pleaseSelectUser: "Please select user",
            timeStartStimuliTo: "to",
            timeStartStimuliStart: "Start time",
            timeStartStimuliEnd: "Rnd time",
            pleaseSelectSortType: "Please select sort type",
            sortText: "Sort",
            level: "Level",
            gameSessionFinishedOption1: "Finished",
            gameSessionFinishedOption2: "Not finished",

        },
        questionReport: {
            pleaseSelectSessionType: "Please select questionnaire type.",
            pleaseSelectTest: "Please select test",
            pleaseSelectLevel: "Please select level",
            number: "Number",
            name: "Format",
            totalUse: "The number of times this item has been tested.",
            totalPass: "Number of users who passed this test",
            percent: "Number of users who passed this test",
            avg: "Average Durationresponse",
            sd: "Standard deviation of duration response",
            percentPass: "Percentage of correct answers (if that level is passed)",
            question: "Question",
            answer: "Answer",
        },
        statisticsReport: {
            findSessionType: "Find SessionType",
            pleaseSelectTest: "Please select test",
            pleaseSelectLevel: "Please select level",
            playTotal: "Number of tests",
            playSucceed: "Number of successful tests",
            avg: "Average",
            sd: "Standard deviation",
            avgSDDescription: "(Time spent playing the quiz until the test is successful)",
            tableName: "Table of cumulative frequencies of people who reached the maximum at this level",
            level: "Level",
            frequency: "Frequency",
            cumulativeFrequency: "Cumulative frequency",
            percentFrequency: "Frequency percentage",
            percentCumulativeFrequency: "Cumulative frequency percentage",
            tableAVG: "Average of time in level",
            tableSD: "Standard deviation of time in level",
            mobile: "Mobile",
            tablet: "Tablet",
            computer: "Desktop",

        },
        statisticsPeriodReport: {
            totalTest: "Initiate test",
            totalTestSuccess: "Succeeds but may have null",
            totalTestFail: "Null can either finish or not finish.",
            playPercentSeries: "Test percentage",
            mobile: "Mobile",
            tablet: "Tablet",
            computer: "Desktop",
            avgSD: "Average and standard deviation of time spent in period mode",
            sd: "Standard deviation",
            avg: "Average",
        },
        setting: {
            tableTest: "Table Test",
            name: "Name",
            casual: "Casual mode",
            period: "Period mode",
            openToPlayInCasualMode: "Open to play in casual mode",
            openToPlayInPeriodMode: "Open to play in period mode",
        }
    }
}


