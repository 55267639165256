import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  timeout: 1000 * 1000,
});

export const HTTP_WEB_MAIN = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_WEB_MAIN,
  headers: {
    "key": process.env.VUE_APP_BASE_API_SECERT
  },
  timeout: 1000 * 1000,
});

